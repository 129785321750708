import { useLocale } from '@ecomm/data-hooks'
import { type FeatureFlag, safeMapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import {
  ExitIntentModal,
  type ExitIntentSchema
} from '../experiments/ExitIntent'
import { useExitIntentExperienceProps } from '../experiments/ExitIntent/useExitIntentExperienceProps'
import type {
  ModalTypes,
  SendLinkToCustomerModalProps,
  TwoButtonsProps
} from '../lib/ModalContext/types'
import { ModalTwoButtons } from './ModalTwoButtons'
import { SendLinkToCustomerModal } from './SendLinkToCustomerModal'

type CustomModalProps = {
  readonly isOpen: boolean
  readonly modalType: ModalTypes
  readonly onRequestClose: () => void
  readonly assets: ExitIntentSchema
  readonly twoButtonsProps?: TwoButtonsProps | null
  readonly sendLinkToCustomerProps?: SendLinkToCustomerModalProps | null
}

export function CustomModal({
  isOpen,
  modalType,
  onRequestClose,
  assets,
  twoButtonsProps = null,
  sendLinkToCustomerProps = null
}: CustomModalProps) {
  const exitIntentExperimentData = useExitIntentExperienceProps()
  const exitIntentExperiences = useMemo(
    () =>
      safeMapExperiences<FeatureFlag['data']>({
        nt_experiences: exitIntentExperimentData.nt_experiences
      }),
    [exitIntentExperimentData]
  )
  const locale = useLocale()

  return (
    match(modalType)
      .with('exit-intent', () =>
        locale === 'en-US' ? (
          <Experience
            component={ExitIntentModal}
            experiences={exitIntentExperiences}
            id={exitIntentExperimentData.id}
            json={exitIntentExperimentData.json}
            passthroughProps={{
              assets,
              isOpen,
              onRequestClose
            }}
          />
        ) : null
      )
      .with('two-buttons', () =>
        twoButtonsProps ? (
          <ModalTwoButtons
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            {...twoButtonsProps}
          />
        ) : null
      )
      .with('send-link-to-customer', () =>
        sendLinkToCustomerProps ? (
          <SendLinkToCustomerModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            {...sendLinkToCustomerProps}
          />
        ) : null
      )
      // add more modal types here
      .with(null, () => null) //no modals to render
      .exhaustive()
  )
}
