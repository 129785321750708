import { Locale } from '../../hooks/useLocalizedData'

export const data = {
  [Locale.EnUS]: {
    image1: {
      description:
        'Image diagram of the Wireless Indoor Camera and its features when the shutter is closed.',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=299&h=345&q=50&fm=webp 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=597&h=689&q=50&fm=webp 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=1194&h=1378&q=50&fm=webp 1194w',
              sizes: '(min-width: 1194px) 1194px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=299&h=345&fl=progressive&q=50&fm=jpg 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=597&h=689&fl=progressive&q=50&fm=jpg 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/vlYJLsCKAtZIvkFVaqcN3/247bd04ac1b9099ac6955a33b4f4f7e9/Scout_diagram_v2_-_shutter_closed_1_2x_-_jpg.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg 1194w',
            sizes: '(min-width: 1194px) 1194px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1194,
        height: 1378,
        placeholder: {
          fallback:
            'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAYHBf/EACYQAAIBBAEDAwUAAAAAAAAAAAECAwAEBRESEyFBFTHBBiIjUaH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIRAxIx/9oADAMBAAIRAxEAPwDgYmx9QykFvOzIshO2I76A3r+VoiY229NntoUXiYiirr22KzzCyvBmrNm2v5VHcfs6rUo1UI5UHZ81pk6REzCfA39u4R0TZG+zilVeS2Z1378Pk0qlJhqQyTusqOTviwNXs/1fjEsJehMzT9M8F6ZH3ePGqUqnFME6IaW7ldgTM57eXY/NKUpoD//Z'
        }
      }
    },
    image2: {
      description:
        'Image diagram of the Wireless Indoor Camera and its features when the shutter is open.',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=299&h=345&q=50&fm=webp 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=597&h=689&q=50&fm=webp 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=1194&h=1378&q=50&fm=webp 1194w',
              sizes: '(min-width: 1194px) 1194px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=299&h=345&fl=progressive&q=50&fm=jpg 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=597&h=689&fl=progressive&q=50&fm=jpg 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/7tU8EMr6rDlMdjFbuSvpJw/b6ca2f1c67125f4ab9c4400db618e995/Scout_diagram_v2_-_shutter_open_1_2x_-_jpg.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg 1194w',
            sizes: '(min-width: 1194px) 1194px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1194,
        height: 1378,
        placeholder: {
          fallback:
            'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAYHBf/EACUQAAICAgEDAwUAAAAAAAAAAAECAwQAEQUSITEGQVETIjJxwf/EABcBAAMBAAAAAAAAAAAAAAAAAAECAwD/xAAZEQACAwEAAAAAAAAAAAAAAAAAAQISIRH/2gAMAwEAAhEDEQA/AOJxlePkOTr1pXIV218+2/nLyjX4+zQngptG8aq0WgPB8ZA8W5Xl6jAN9kqk7PgbzTalaCukxghWPrbqJUABj85SeMWKwzmX09djYBoFB1vtKMZX2gTKNj2/uM12CqM+r2XglDgkkHeXj+sONWlIYpHab6Z6UKH8tdgT+8YyjgpPRbNLhCvPbJBeeUkjfdzjGMID/9k='
        }
      }
    }
  },
  [Locale.EnGB]: {
    image1: {
      description:
        'Image diagram of the Wireless Indoor Camera and its features when the shutter is closed.',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=299&h=345&q=50&fm=webp 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=597&h=689&q=50&fm=webp 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=1194&h=1378&q=50&fm=webp 1194w',
              sizes: '(min-width: 1194px) 1194px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=299&h=345&fl=progressive&q=50&fm=jpg 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=597&h=689&fl=progressive&q=50&fm=jpg 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/1bs50yZsBalBnNeDUjdif3/e0e3261112241bf75652a215c4f6c7d3/Scout_diagram_v2_-_shutter_closed_1.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg 1194w',
            sizes: '(min-width: 1194px) 1194px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1194,
        height: 1378,
        placeholder: {
          fallback:
            'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAYHBf/EACYQAAIBBAEDAwUAAAAAAAAAAAECAwAEBRESEyFBFTHBBiIjUaH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIRAxIx/9oADAMBAAIRAxEAPwDgYmx9QykFvOzIshO2I76A3r+VoiY229NntoUXiYiirr22KzzCyvBmrNm2v5VHcfs6rUo1UI5UHZ81pk6REzCfA39u4R0TZG+zilVeS2Z1378Pk0qlJhqQyTusqOTviwNXs/1fjEsJehMzT9M8F6ZH3ePGqUqnFME6IaW7ldgTM57eXY/NKUpoD//Z'
        }
      }
    },
    image2: {
      description:
        'Image diagram of the Wireless Indoor Camera and its features when the shutter is open.',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=299&h=345&q=50&fm=webp 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=597&h=689&q=50&fm=webp 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=1194&h=1378&q=50&fm=webp 1194w',
              sizes: '(min-width: 1194px) 1194px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=299&h=345&fl=progressive&q=50&fm=jpg 299w,\nhttps://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=597&h=689&fl=progressive&q=50&fm=jpg 597w,\nhttps://images.ctfassets.net/v6awd0kabi65/1z1JuS2nSCuaInMCcz9fcO/16141992ab65489c48b1a1471ddb0256/Scout_diagram_v2_-_shutter_open_1.jpg?w=1194&h=1378&fl=progressive&q=50&fm=jpg 1194w',
            sizes: '(min-width: 1194px) 1194px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1194,
        height: 1378,
        placeholder: {
          fallback:
            'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAYHBf/EACUQAAICAgEDAwUAAAAAAAAAAAECAwQAEQUSITEGQVETIjJxwf/EABcBAAMBAAAAAAAAAAAAAAAAAAECAwD/xAAZEQACAwEAAAAAAAAAAAAAAAAAAQISIRH/2gAMAwEAAhEDEQA/AOJxlePkOTr1pXIV218+2/nLyjX4+zQngptG8aq0WgPB8ZA8W5Xl6jAN9kqk7PgbzTalaCukxghWPrbqJUABj85SeMWKwzmX09djYBoFB1vtKMZX2gTKNj2/uM12CqM+r2XglDgkkHeXj+sONWlIYpHab6Z6UKH8tdgT+8YyjgpPRbNLhCvPbJBeeUkjfdzjGMID/9k='
        }
      }
    }
  }
}
