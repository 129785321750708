export const assets = {
  background: {
    contentful_id: '2AB2d5pVj16iZItrZqOx5d',
    title: 'Shield Modal BG',
    url: 'https://images.ctfassets.net/v6awd0kabi65/2AB2d5pVj16iZItrZqOx5d/5b1db4026879652a81da0900d5610bd9/Shield.png',
    originalWidth: 1485,
    originalHeight: 1751,
    description: 'Navy blue outline of a shield'
  },
  specialist: {
    contentful_id: '6UMbGUo2vMm65W8HkHT2ni',
    title: 'Call A Sales Specialist',
    url: 'https://images.ctfassets.net/v6awd0kabi65/6UMbGUo2vMm65W8HkHT2ni/c45be88502d1b22da6f4c692f7ee6a24/Frame_3421.png',
    originalWidth: 150,
    originalHeight: 150,
    description:
      'Image of a home security specialist with a headset on and speaking to a customer'
  }
}
