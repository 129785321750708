import { Locale } from '../../hooks/useLocalizedData'

export const data = {
  [Locale.EnGB]: {
    image: {
      description: 'Whole home security at an unbeatable price',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=453&h=346&q=50&fm=webp 453w,\nhttps://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=905&h=692&q=50&fm=webp 905w,\nhttps://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=1810&h=1384&q=50&fm=webp 1810w',
              sizes: '(min-width: 1810px) 1810px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=1810&h=1384&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=453&h=346&q=50&fm=png 453w,\nhttps://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=905&h=692&q=50&fm=png 905w,\nhttps://images.ctfassets.net/v6awd0kabi65/muWXpRLZYLkTe9ILZurKb/dc47b28a653b6e455352b5a85caa338f/Frame_1028__1_.png?w=1810&h=1384&q=50&fm=png 1810w',
            sizes: '(min-width: 1810px) 1810px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1810,
        height: 1384,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAMAAADTRh9nAAACslBMVEUGJUYHJkcHKEsIK1AILlQJL1YJMFgJL1cILFAHKUwHJkgHKU0ILVMJMFkKM10KNWAKNmIKM14JMVkrOkNQSDIgMj8GJkcJMlsKNmELOWYLO2oMPGwLOWd+Yy/4lwLwkwPojwZhUC0HKEoILVIJMloLOmkMPm8NQHMNQnUNQXM3TFfvkgTWhwvEfg/QhAzkjQcVLEIIKk4LOmgMPnBRcZCboaist8Fsi6oORnwNQ3dcXkzylAPGfw7Pgwzeiwj3lgI0OzoHJ0gILFELN2QMPW10iqDIv7bf29iar8MPS4UOR34rTmnokAfljgbmjgXojwXaiQoOKUQHJ0oMP3AORHl6j6Pe29ehtskYP2MZOlkbNlNsYEDjjgrxlAPUhg1CQzguRF1fanxZZXcWQWwORXuBlKbe2tepvM4iKjGdnZ1WVlUjP1sSPWYnQFQMMFRzgI7Z5O/s7vArUHWImKfIv7fc2dWxwM9MU1lqaWdZV1ZKX3N0iJsvUXPk6vDy8vIqTnKao6zIwLnY1NDBydC8wsbMyMPNyMOdqLNoYl5bcokHJ0locHrMwLbVxbhbdI1IbI+6urrTzsnW1dO/wsTOycOdp7FedYwIK0/digncignFnWLt5Njl4NjY1dLe29nb1tLDvLXZ1tHY1dHPy8fQy8bZvpXX083frmHbjRTciwvrjwDmjQDGkELXxbPZzsPDu7Pa1tLb2dXOz8jT1dDS19PV0svPycPSy8TivYLRycDSxbfXzMLxr0P7nQDfiQDhiQDljB/alEzZr4LdzLnezbjdu5DNlE7RnFrdo1nlpVDirGXkrGPrqUXavpjUuZfZxaz1tj//qgbzlgD2lwD7mQr2mx7znCXymybwnjLvoT3tnjjso0bupkrwpkXypDz1pTf5pSv4pS/8piX+pRv/pQ3+owFdDD2IAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wcIECIpSScIWQAAAKlJREFUGNNtzc0KAVEAxfHzn+5C6prBmI00GlvJzk6ex947eAALW6/hOaSsRLJB8lUsGBt3SpzV6VengyQBgHga7qkkJOU/mHqcfdgJKbo9yh+8WoAlUgL7ipsDYIQ/zzX4ilGPoMmm9o3FqbfoBJz8zCYo6QKwqjsbvYzaPrCOW86GkqejrLUzmyUeCkUDAE4BXAocQvopYzfbVl07mpKr5ew9NCG/+YtvuRwj3F/7ge8AAAAASUVORK5CYII='
        }
      }
    },
    headline: 'Whole home security at an unbeatable price',
    body: {
      raw: `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Break-ins, fires, floods and more - SimpliSafe® has the technology and the know-how to keep your family protected. See all the options for your home and budget.","marks":[],"data":{}}]}]}`
    },
    buttonText: 'Meet the system',
    buttonLink: '/meet-the-system'
  },
  [Locale.EnUS]: {
    image: {
      description: 'Whole home security at an unbeatable price',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=341&h=260&q=50&fm=webp 341w,\nhttps://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=681&h=519&q=50&fm=webp 681w,\nhttps://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=1362&h=1038&q=50&fm=webp 1362w',
              sizes: '(min-width: 1362px) 1362px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=1362&h=1038&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=341&h=260&q=50&fm=png 341w,\nhttps://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=681&h=519&q=50&fm=png 681w,\nhttps://images.ctfassets.net/v6awd0kabi65/7l0o0nsnpaTWNQvZER9GWn/251d7631ebb6b381217f85a8832d9320/image_169.png?w=1362&h=1038&q=50&fm=png 1362w',
            sizes: '(min-width: 1362px) 1362px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1362,
        height: 1038,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAMAAADTRh9nAAACl1BMVEUHJkcGJUYGJkcGJkYFJkYyOjpTSjEgMj8GJ0cGJ0gGJ0kFJ0kFJkmMZCD5mAHwkwPmjgZbTi4HJ0extbbIysrU1tcxTWkGKk0GKk5CRDrwkwTShQzEfg/WhwrfiwgPKkQLK0u3urrHycjZ2to9WnYHL1YHMFdmWDT0lQLAfA/RhAvhjAf3lgInNjwQMFC4u7pFZIEINF4INWAcL0EjM0NPRDLskQTkjgbmjwXpkATVhwwKKUgFJkgGKEoVNla5u7vZ2tlMbYoIO2cJPGoiJiqurq5sbW18WB7okAfylAPThg0+RD0GKUwFJUYEJUgFJ0obPV66vbzHyslWeJYIP3EJQHI/RUlVV1hKTE0tRFkUP2IsRVMLM1oGLlUEJUcEJkkFKUwGLFIrTWy/wcHKzMvb29tsiqUIQHQJQHS2vL+9v796k6kIP28IOmcINWEFMVoGLVIEKEoFKk4GLlZEYHrAw8LJzMvZ29qNobQcTnsbTny3vcC/wsJ7k6kJQHMIPWwHOGMHMl0EJkgFK08FLlZbcoa/w8LV19fGyMq9v8HKy828vr95kqYPQ3IhT3lAZIQzVXYEJUaUnKW6v8W4v8ZkiZq7zdDD09bJ2Nu/x8qztbjExsjHycu3ubu6vL6+wcO5vcHCxMa3u74GLVEGI0GMkZaYnKGPlp03SFtUX2tga3dganWQlpzAwsSZoal3go12gYyfpauytLevs7mUm6R7hpQFKEsIKEgcNVIkO1cjPVgoQFo5TmZDWG1RY3ZLW3BHV2xQX3RTYXVJWW1KWm5IWGxAUWc8TmU1SWEpP1kGKEkKKUkWME4bNFEYNFIjO1Y5TWRKW25UY3ZHV2s3SGE8TGRCUmhAUGU/T2Q1R18jOlUYMlARL02bWqg/AAAACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH5wUFEzAaBO8seAAAAJBJREFUGBkFwbFJQ2EAgMHv5BcMSIgmjYVVQEhnYSPO4Aru5BIu4QK2doLiCi+QJinkeaeqqlRzVaOqqjD/nXNKVWMcFxQwqboGCjCqlkABRrVYOnQJgJEnHG4BwDMfj3zeA/DuBQBOF2DstwBMN+BnPExX7FeYz1ZfO8grAECMNYDvO4CxBrABMDYAgN+tt3/NBhiKlKROQgAAAABJRU5ErkJggg=='
        }
      }
    },
    headline: 'Whole home security at an unbeatable price',
    body: {
      raw: `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Break-ins, fires, floods and more - SimpliSafe® has the technology and the know-how to keep your family protected. See all the options for your home and budget.","marks":[],"data":{}}]}]}`
    },
    buttonText: 'Meet the system',
    buttonLink: '/meet-the-system'
  }
}
