import type { ScoutVideoType } from '.'

// TODO: We will revisit the styling aspects of these props when we have the correct
// video assets (based on aspect ratio and resolution) to ensure they can work
// accross our different viewports.
export type ScoutVideoMetadata = {
  readonly videoSrcUrlWebm: string
  readonly videoSrcUrlMp4: string
  readonly captionsSrcUrl?: string
  readonly poster?: string
  readonly preload?: string
}

const AlarmMonitoringScoutVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/monitoring_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/monitoring_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/monitoring_caption.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/monitoring_hp_poster.jpg',
  preload: 'none'
}

const ScoutProductLiveGuardVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://videos.ctfassets.net/v6awd0kabi65/eDRGuzk9f13YVnsQZBeRu/376c23e0205b1ec8f6c751dabb29e799/scout_uk_pdp.webm',
  videoSrcUrlMp4:
    'https://videos.ctfassets.net/v6awd0kabi65/Q68Bqx9aRQq0KtfrYFyiK/8e24da0dd8d70732b74d175d1852f8cf/scout_uk_pdp.mp4',
  poster:
    'https://images.ctfassets.net/v6awd0kabi65/7KjAowFloU0onXPr69vIPr/5e5f936c90e5fec0eb45cdcc06cf1b4e/scout_video_poster_1.jpg',
  preload: 'none'
}

const ScoutProductInstallationVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_installation_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_installation_video.mp4',
  poster:
    'https://video.simplisafe.com/marketing/scout/scout_installation_poster.jpg',
  preload: 'none'
}

const ScoutHomePageVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_hp_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_hp_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/scout_hp_captions.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/monitoring_hp_poster.jpg',
  preload: 'none'
}

export const data: Record<ScoutVideoType, ScoutVideoMetadata> = {
  AlarmMonitoringScoutVideo,
  ScoutProductLiveGuardVideo,
  ScoutProductInstallationVideo,
  ScoutHomePageVideo
}
