import { Locale } from '../../hooks/useLocalizedData'

const scoutSensorImage = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/vErNBFFERCtDMhjJN7xVO/85f16a8d27f54e455377404b6bc9d194/ImgGallery_Scout-main.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAABQIDBP/EAB8QAAICAQQDAAAAAAAAAAAAAAECAxEABBIhMQUTgf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A3eMp5YweQzAHCGlnDR6qVSEEYrZV31zeIWg8ZIJpI0ilRhQ2sDmJWiOqm9su5CPmatCAeSu8NT//2Q=='
    }
  },
  description: 'Scout Sensor'
}

const scoutCompactSize = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/7wfHq0hlGEXDpHGZwSwkt8/b31e7ec62ed247a6d46df6c8697fe080/ImgGallery_Scout-4.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQG/8QAIhAAAgEDBAIDAAAAAAAAAAAAAQIDABESBBMhMQVxFEGB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//EABkRAAMBAQEAAAAAAAAAAAAAAAABESESAv/aAAwDAQACEQMRAD8A0DyEzFQedywv+1ndhcwZDaQqe+qskUNxTAkkEKyNIzuAHLdDg1PKTo+sgL+W0y5SpHPMcgMFUXP1xST8tk9ZCrRaj5UJfYmhs1sZkxPv1WjUC0//2Q=='
    }
  },
  description: 'Scout Compact size'
}

const scoutEasyInstall = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/22mL2McOXvaYhNEXcN7Xzd/5664a5cb591edddb4a5b012f4965c774/ImgGallery_Scout-2.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAQUCAwT/xAAjEAACAQMDBAMAAAAAAAAAAAABAgMAERMhMUEFEiJRMmKh/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgMAAf/EABsRAQEBAQADAQAAAAAAAAAAAAECABIRIjEh/9oADAMBAAIRAxEAPwCHS4+4ZgAybAXtrSYSeX7pFHXWtkmshgXtU3uC7WAHF6HS+pq8h7VhPJlcGPCgCgfLc+9BTB06Rcr6TO07zFICxQBmYNYcgb6c1yoSDl+6pQ0tbepEKzzYJ5HIMa+ANrrvofZ/KMyfDa6X9cjmnZZCMUun1pr4wJ87/9k='
    }
  },
  description: 'Scout Easy Install'
}

const scoutFastCharging = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/3HkSNfQvaK7yLPNqfrf34H/9c4c3936fc25395f471219bef60ea659/ImgGallery_Scout-5.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAABQMEBv/EACAQAAIBBAMBAQEAAAAAAAAAAAECAwAEETEFEiFBMmH/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/8QAGBEBAQEBAQAAAAAAAAAAAAAAAAERMQP/2gAMAwEAAhEDEQA/ADbbjOl2uXM0SjYHX2sp5w+NJZSQvKsCuVlOkIzqrwaSDEZAK+f3VMArfjAUwsrqp+VUoxNFYpx0yXixzTvGThUXsfRjQxR2lxYtGkuFeQpLES35kj6nQ+ZqbMOXX//Z'
    }
  },
  description: 'Scout Fast Charging'
}

const scoutMountingOptions = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/v6awd0kabi65/2Mk39e7J0MM02gvaVSxxzn/5e25539a35414a93a6a2ac366f4c85d8/ImgGallery_Scout-3.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQMG/8QAIRAAAQQCAQUBAAAAAAAAAAAAAQIDBBEAEhQFITFRcaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABcRAQEBAQAAAAAAAAAAAAAAAAABESH/2gAMAwEAAhEDEQA/AEYri3eomPSdQB5B9ZFWlzJDKYzTCtbBVVeTtVfuA+2VFAKhR+3gZ2PHUzM5G99qqsC8wNNphyHI0qQpsq1EdG1fcsmpbhGFN5jJc477FK11fRqo9vNeu+LMJ1//2Q=='
    }
  },
  description: 'Scout Mounting options'
}

const createData = (body: string) => ({
  description: {
    raw: body
  },
  features: ['shippingTruck', 'shield', 'professionalMonitoring'],
  productImages: [
    scoutSensorImage,
    scoutCompactSize,
    scoutEasyInstall,
    scoutFastCharging,
    scoutMountingOptions
  ],
  productName: 'Smart Alarm Wireless Indoor Security Camera',
  showNewFlag: true,
  buttonText: 'Build a system',
  buttonLink: '/build-my-system',
  productSku: 'CM006-01DWW',
  className: 'mb-4 md:mr-4 md:mb-0 w-full md:h-full'
})

export const data = {
  [Locale.EnGB]: createData(
    `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Only SimpliSafe® offers an indoor camera with smart motion detection that can instantly trigger the alarm.","marks":[],"data":{}}]}]}`
  ),
  [Locale.EnUS]: createData(
    `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Only SimpliSafe® offers an indoor camera with smart motion detection and 24/7 live guard protection.","marks":[],"data":{}}]}]}`
  )
}
