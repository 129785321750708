export const data = {
  backgroundImage: {
    description:
      '24/7 monitoring by professional agents (Hero Banner - Desktop)',
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=786&h=359&q=50&fm=webp 786w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=1572&h=719&q=50&fm=webp 1572w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=3144&h=1437&q=50&fm=webp 3144w',
            sizes: '(min-width: 3144px) 3144px, 100vw',
            type: 'image/webp'
          }
        ],
        fallback: {
          src: 'https://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=3144&h=1437&q=50&fm=png',
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=786&h=359&q=50&fm=png 786w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=1572&h=719&q=50&fm=png 1572w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pVH7CTJxiB4Lx2pgiqgYo/2056510e351a4c66ec644d8cd01a4765/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_1.png?w=3144&h=1437&q=50&fm=png 3144w',
          sizes: '(min-width: 3144px) 3144px, 100vw'
        }
      },
      layout: 'constrained',
      width: 3144,
      height: 1437,
      placeholder: {
        fallback:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAMAAAAFH/x6AAAB41BMVEUpKiwoKisnKCkkJiciJCUhIyQiIyQjJCUkJSYvMC9QUEx3dnB/fXgxNT0lKTI3NjU2NzlkZGewqaSro587PkE9QEI/QUQ+QENAQkU+QUMjJSUkJScuLi5DRkZaVVFUSkdRUFRvbWxeUkBCQkJvb3K5sq2yq6YpLi01Ojs3Ozw2OTs6P0AmJygkJig5Oz09Pz9XYmdWUE6JaWN+dXZASVU7RVNcYmpvcHR3c3O1rqoeISIoKSggIiInKigfIh8iJCMhISMzMzU7PTxTVlc8PkZxXVpPU11fZm1NVmBLRkV0fHxmYmCak44ZHB4WGBsbHB4ZGh0YGhwXGRoYGRonKCojJSxRTkU1OD1CRkpSU1RYV1UrIiFQTEwmJSdOS0gVFhkUFhkaGhwSFBcTFBcUFhgdHh8TFRgcGxwXGBsSExguMDs5MzVOR0ZwY14uLSw5OjtBQEBZVlMTFRcXGBkcHBwoJyQqKCQqKSUXGBoQERUPDhJ7WlI2Kyo7MzJhVVVdTEY/MStHOzZWTEhZUU4RExUVFhgVFxkWFxkQERMICAtdRD94XFVxWVN7Ylp+X1ZaQDdRPjZTQzxTQjwRExYSFBYUFRcPEBIKCg8ODhQTExoNDREGBggxJR5QOSxdRDlhSkBTQDhB7IlnAAAACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH5wUMEwcDjYtrBgAAAFVJREFUCNdjZGDEBCwiEPqNCLKgMJB4LcYojKJSGKhfGF07VOAvy3ErhKAQjOULJjcGgMgixgeKIPq5FCOySuH/99+b3P2kiSTI/5GP8b/AHX5DJIsARjURSlVUPi0AAAAASUVORK5CYII='
      }
    }
  },
  backgroundImageMobile: {
    description:
      '24/7 monitoring by professional agents (Hero Banner - Mobile)',
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=375&h=242&q=50&fm=webp 375w,\nhttps://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=750&h=484&q=50&fm=webp 750w,\nhttps://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=1500&h=968&q=50&fm=webp 1500w',
            sizes: '(min-width: 1500px) 1500px, 100vw',
            type: 'image/webp'
          }
        ],
        fallback: {
          src: 'https://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=1500&h=968&q=50&fm=png',
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=375&h=242&q=50&fm=png 375w,\nhttps://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=750&h=484&q=50&fm=png 750w,\nhttps://images.ctfassets.net/v6awd0kabi65/g7byqrCtiLAWW2aGMmYXy/9cd7c69d26187a894ad40ab82d53fc77/230411_SimpliSafe_MonitoringCenter_B_Screen_091_Panorama_FIN_2__1_.png?w=1500&h=968&q=50&fm=png 1500w',
          sizes: '(min-width: 1500px) 1500px, 100vw'
        }
      },
      layout: 'constrained',
      width: 1500,
      height: 968,
      placeholder: {
        fallback:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUQECUiftlrBgAAAtRJREFUGBkFwUmIW2UAAOB/f1veluUlqZPZ2qZQA0JHBxfaQ0VBRE+KePDs3ZM3r+LVqze9eBFbkA4IojhTe1A7Ik61nU7bSZNMXl76trx9+f0++MF77xJCCCWiIDLGJElKslwQBKvdzIryq69vQogIoZZOdKne6KDbx6hp6p98/BGEkHDAg3BFCCnEkjLKIXD9uMgcWudnM3utZ50brG9vbRir33npPlgQACGjAmOMc04aDaW3fnF39+Uf9/befuvNG7f2WHt9fnRY2eNFzF/Y7AeoHk/mvzyqi0LhHFAKGGOzxVISKGGU+J63sbX5/Gg0HA6V/YP+hWH+7ExY8p0L3Z0XR1/c+itNzgDCmqmqmtyz2i1Z+Pa7m9de2UUII/v4vnfvblUVqzhSRMiX46qsBoPB7pWR60VNXe209LXnenFW1oHdxJXVt8LQB5wT2/UqQJ6GSUMRnrmOJMpH/z46HS/uOvPf/vmPaN2QMw6qD9+5fn/ifvPl5w0CkKFjSv3Ax2mKBIkJqrpw/Du37xwfP7GdgBEYrsIS0yhJUJ1F3hLU6aVBe//X/bSCaRQTjH76+QCbhrFcrkieXT2vHvz95Ol47LnB1Ze2W0qBmSJKTG2Ir+6M8rL4/sYPW4YwddzEcYyOBUGNO52W5/p5Es2m85mfup5fV9XCrx9PQoNBRRKiKGmKSBbZYK2fIHnquFTVqppTDInWoNNpafuJG2UQIcBBzSu5Tso6m9lpJ/a7ZlMKo5PpLE7imgOSr84Z3YvDbUwZfv21yyenc0axKNAoTqqyQqD89P03hl3t3ngaJ2mptXJZA5ybDKdJIhLCEAw9Nw58cng0aZpmx2qXRRFEp2VRZnnxcHJ25dLmZ8PzBQeYUKWharqxtBd//Hl4ePIAYsIRJhDAVsvKsxxCKEksSdIsLyBAPVPVFYVQluVpEMd5nl3um7os1xwIksARqhEmGP8P7MZ1KHGGJQoAAAAASUVORK5CYII='
      }
    }
  },
  fastProtectLogo: {
    contentful_id: '4D8ucZ2G7JX2rtJHV61XhK',
    description: 'SimpliSafe FASTProtect TECHNOLOGY (Hero Banner)',
    title: 'SimpliSafe FASTProtect TECHNOLOGY (Hero Banner)',
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=83&h=47&q=50&fm=webp 83w,\nhttps://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=166&h=93&q=50&fm=webp 166w,\nhttps://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=331&h=186&q=50&fm=webp 331w',
            sizes: '(min-width: 331px) 331px, 100vw',
            type: 'image/webp'
          }
        ],
        fallback: {
          src: 'https://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=331&h=186&q=50&fm=png',
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=83&h=47&q=50&fm=png 83w,\nhttps://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=166&h=93&q=50&fm=png 166w,\nhttps://images.ctfassets.net/v6awd0kabi65/2n3tf5tm52On6IHyXzXBJb/83cc957e06636649db3dc464a85f2d4c/image_2.png?w=331&h=186&q=50&fm=png 331w',
          sizes: '(min-width: 331px) 331px, 100vw'
        }
      },
      layout: 'constrained',
      width: 331,
      height: 186,
      placeholder: {
        fallback:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAMAAABI111xAAABAlBMVEUAAAD80ZH////////////////////////////////////////////////////////////////////////////96cz70pb////////////////////96tH3mB33mB33mR7////////////////////////7z5f3mB33mB33mB33mB33mB33mB33mB33mB33mB3////////////////////////3mSD3mB33mB33mB33mB33mB33mB33mB3////////4qkX3mB33mB73mB33mB33mB33mB33mB73mB3805T////////////////////94Lz3mB33mB73mB33mB33mB34mR7IJ0N+AAAAVnRSTlMAAAkkGRUBHGZ7eG5xdFsaGBQSEA0CASMtBTcsLzMCD49vuk+kg2OqkGhpjHBrgQ9iU39qhFwiOkpPc11sXAIDBw0KCAkLCgkEAQoOERMWDhARERIOAeg2YBkAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnBQQSKSt3T4gIAAAAaUlEQVQIHY3BwQoBURiA0e+79zfRyLC1UZ5GeV/vMU+gbC2UKOFOZhZkOecwmiQLWX3yE0ysHHR56n1u7xw0+pipjSe4ABukWvunhLYBS79u75V6JXgtpGev1pJS3QY7B4e9R7ZqR2a8D17EE5IQCAaYAAAAAElFTkSuQmCC'
      }
    }
  },
  badge: 'Only from SimpliSafe',
  title: '24/7 monitoring by professional agents',
  description: {
    raw: `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Step up to the highest level of home security. Only SimpliSafe® offers Fast Protect™ monitoring with live guard protection that enables agents to see and speak to intruders. It's industry-leading protection against break-ins and hazards — always without contracts or hidden fees.","marks":[],"data":{}}]}]}`
  }
}
