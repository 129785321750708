import { isBrowserSupported } from '@simplisafe/ewok'
import { getNewRelic } from './getNewRelic'

// custom attribute names
const HOST_PROVIDER_KEY = 'provider'

// values of `provider` custom attribute
const ECOMM_AWS = 'ecomm-aws'

export const isNewRelicEnabled = () => {
  const deployEnv = process.env['DEPLOY_ENV']
  const newRelicDevEnabled = process.env['PUBLIC_DEV_ENABLE_NEWRELIC']
  const nodeEnv = process.env['NODE_ENV']

  //Added test nodeEnv for allowing unit test to run newRelic related APIs
  return (
    (nodeEnv === 'development' && newRelicDevEnabled === 'true') ||
    nodeEnv === 'test' ||
    (deployEnv !== 'development' && nodeEnv !== 'development')
  )
}

/**
 * If the handler returns true, New Relic does NOT record the error.
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/set-error-handler/
 */
export const errorHandler = <E>(__err: E) => {
  const isSupported = isBrowserSupported(navigator.userAgent)

  if (isSupported && isNewRelicEnabled()) {
    return false
  } else {
    return true
  }
}

export const configureNewRelic = () => {
  getNewRelic(newrelic => {
    // configure error handler
    newrelic.setErrorHandler(errorHandler)
    // set release id
    const release = process.env['PUBLIC_RELEASE_ID']
    release && newrelic.addRelease('frontend', release)
    release && newrelic.setApplicationVersion(release)
    // set custom attributes
    newrelic.setCustomAttribute(HOST_PROVIDER_KEY, ECOMM_AWS)
  })
}
