export const createRichText = (content?: string | null) => {
  return `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"“${
    content || ''
  }”","marks":[],"data":{}}]}]}`
}

export const ukData = [
  {
    color: '#00565C',
    backgroundImage: {
      description: 'Wildfires & looting',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/24hXqllSTgT5ZXUCOSJz4G/e8c95a73d4ec0d6bdac2b13bc9e59515/photo.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACylBMVEVNUFhWVl1WUlJTUldcYGdUVl9mZmxjZGxMSVBYUVR9ZWCOWUt3WFhvZWF6bWWflYrCvbTDx8PC0NeVpbNeX2NbXWNLSk5gYGFla3I7QExRUFtZUFpiRUhSPEGYWkFdQj89PUo/PEUzNkNaYml7gIl+jqB5jJqBi5VYWEtmZk1mZ05qbVVyc2deVk6AXlHBZzmyXTW7WS6nWTg4MzxJO0JdSkpgTU9NTFRTVVhJUlpOWl9sb285PTkxOjJGUTtARDpNUD9dYEdaWkVvbk9uZ0uBb0x3Z0hbWUlkWk5yaFJwZlpRUk5ESkc5QzpVXE5xbF0iKy8hKionMS0mMi0jLikrNS40OzNBRjpMTz44QDc+RTlESz9DSkFNUUJFSkBFSj5ITUNGS0NART5BRT4eKCobJyYbJyQfKSUkLyYnLyglLyokLys5QDUsNjItOTU5RTgvOTQpNDUuNzgsNTQ6QDw9Qz0mMDItNDIeKCwZJSMZJiEaJSIbJiMaJiccKCkcJyckLSsfKys/TTh1hUpHVDgdKi4fLC8lMTIkLzArMzIyOTYzOTUbIygTHiEdKCUsNy0aJycbKSkdKiwZJycaKCkiMCo5RzUvPzAZJiocKCsgKy4eKSshKismLy8rMjA1OjsqMDIyOTNeYkNcZGAkLTMZIycZJCgcKS9PX2lNWFoaJiomMDAiLSwbKCoeKSolLy8tNjIxNzJmZWV4d3WCfndSUEtnZVksMS0eJCkVHCMVHSQqNDx3hIyZnJkYIyghLSYyPS0rNDAhKyw0OjQ1OjQ6PTWknpPAsp6jl4djXVVXU01ZVlNIRUQ8Oz0qLDApKyxSUUx+fmxaYEg8RiIYISQdJiogKS0sNDEtNDQqMS7BtKCpnYxxa2JOTEo9PT0zNTgxMzc6OjtMSENxa2FiY0ZKUhNAThRTYhZqc0U7PkEwNDk0Nzw/QUFCQkPbIg6/AAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAACBJREFUCNdjZGDEAkSwiLHQXfAvC5KgMIRGcS5MkEyLAE7sAueGUXmaAAAAAElFTkSuQmCC'
        }
      }
    },
    deviceImage: {
      description: 'Outdoor Camera',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/CA7XYdmjGssQ6mS5HbkSm/b092c4a0667e10b149b56d791cea5a3b/Shield.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAAB4FBMVEUAAABJSUnf39/g4ODi4uLj4+Pb29vc3Nzd3d3f39/g4ODW1tYAAADX19fb29ve3t7U1NQRERHX19fc3NzR0dHT09PZ2dnNzc0EBAQFBQU7OzvW1tYEBATT09MEBASZmZm1trYEBARPUFClpqYCAgKfoKABAQEAAAADAwOQkJA9Pj6jpKQEBAQGBgaUlJS2trZfX1+ioqJGRkahoqKLi4usrKxFRkagoaEAAAAAAAAAAACUlJRFRUU5OjpWV1ehoqIAAACCgoJFRUU8PD09Pj5WV1efoKDf39/g4ODj4+Pc3Nze3t7k5OTa2trb29vd3d3h4eHl5eXW1tbZ2dni4uLU1NTX19fi4+MEBAQbGxvQ0NDV1dXY2NgFBQUTExPExMTT09PT1NSkpKSKiooGBgYWFhabm5vNzc3R0dFsbW0XGBkRERIgIB8zNDMDAwNERETBwcGurq4hISIICQkPEBEZGRovLzBLTU07PDwAAADIyMimpqYUFBUJCgoLDAwZGhonKChJSkpeYWHOzs7AwMAcHB0YGhtYWlpMTk44OjphZGQ8Pj7Dw8NgYWEMDQ0UFRYcHyAjJidBRERlaGhJS0svLy8ODxAXGRogIyQuMDFcX19NT08SExMcHh8oKystLzDw/HBcAAAAR3RSTlMAACF3pp8/EJv8+kMBI9ztJALN1g8etwMKhauOkmPm+T325wfEITru7uzoAwkOLflxf7QDBtCxCQIDBDb88nICBWDX68Z5FfzZTyYAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQoNJzMNBa6uAAAA8UlEQVQYGd3Bvy5DYRzH4c/3fX/nryMSDCohwiCSGjQRSxE3YBKzK+sgMRhsJltjloh0bSJpYjGQ0OL0UOecugrPw78jak6lnD+iFEkHUldDpkSG1FaB7Fav1Dxh4Pe954dofTAzouLJZw/tuwgKirjxlA0puaVG4H1oYzP7iI+MillbUpiboHhLd90AMBdIGoU5KliW3NojOB+VJn6O8cpqkiTHbgOcTz+TZGFCsJnWTh34xf7edb/19b4VTl3ZM6Kp3rZOpJd5VS7uwejBw04ipap0POCo+Mt46jy4A5TRlJDOJHWkLiVB1nQhqt1Q+wVcBjRdNomNYQAAAABJRU5ErkJggg=='
        }
      }
    },
    title: 'Wildfires & looting',
    description: {
      raw: createRichText(
        'I was recently evacuated from my home because of wildfires. Without the ability to monitor any potential damage to my home or deter potential looters with the 24/7 monitoring, I would have been a nervous wreck. This gave me huge peace of mind.'
      )
    },
    author: 'Anonymous',
    listTitle: 'Outdoor Camera + Monitoring',
    list: [
      '2-way audio allows you to speak through the camera',
      'Agents can use video recordings to verify alarms for priority dispatch*'
    ],
    note: '*Requires select professional monitoring plans with video verification.'
  },
  {
    color: '#006FEE',
    backgroundImage: {
      description: 'Wedding day flood',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACx1BMVEUnLT0wOEkoLjspLjspLz0uNEIyN0Y1PEs6QVE8Q1M7QFBARlZHS1tARFU8QVJAR1k7Q1Y4QVU1P1MxO081Pk82P1BYZXlhboJndYlaZ3pbZ3pygJNyf5NwfpFhbYA2P1MkLUIlL0UhKT0iKz4wOEtATGNGVG08SmFSX3MpMD5VYXRndYpseY1gbH9cZ3p3hJZzgZRwfZBHUWMzPVAoMUUfJzodJTccJDYdIzQgJjU5RVs/TWYuNEExOEdgbIBlcodteo5fa31cZ3l1gpVzgJN1gpRueoxoc4VkboBmboBXXnBKUWRKTVwsM0QlKzo5RVkaHyofJC9NV2Y9RlZFTl0/R1RNVmZbZnZLVWVHUWA2PEtRVWNzbnZQUmFLTl5pXWQ0MTstMD4pLTskKTYZHSkbICsjKDMjKDImKzUnLDUrLzosMTs3O0U4PUc0OUNmXmF0XVqFcHI2O0lXSVFCOUMoLTsjKDUhJjQbHyweIi4eIy4iJjAiJzEjJzMlKjUyNUAyNkGPhIVcR0mGZWNtZm1bUlqlo6pCRlIkKTUfIzAaHSgcHyofISwlJC8rKTMtKzQzLzk3Mz08N0E2Mz5IRE6qnqCehoSolJKxoaWllJhdXG1PTlstKzUrKTQhISwiISwjIiwsKTIxKzQ2LzhDNz5CNz9HO0NFO0KCd3tzZGplWmF7aXiAcXqViZRGRVU4NEAvKjMtKTIdHikfHyohISspJzEtKjMyLTY2LjY6Mzs7NDw+Nj+yqKlpWF5zXmI4M0I2NEBoYm47O0s4ND4xLDUyLjgaHSkcHyseHysgICsjIy4kJC8mJS8tKjUwLTdPSlPWzMu2q6xcVFpAPkc8OUN3cn45O05HRlQvLDceIiogJCwjJy4nKzApLTAsMDMrLzIvMjQxNDWRjY3WzcuIgYZTUl1WVFxwbW1VVGAuMkMyNTwuMTIsLzGdP+LcAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAACdJREFUCNdjZGDEAkSwiLHQRPANVPiNKEJQSBjTGRRZdNSG8SYWlQAR9gXMDX4g0QAAAABJRU5ErkJggg=='
        }
      }
    },
    deviceImage: {
      description: 'Water Sensor',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABfVBMVEUAAAD////Z2dnZ2dnZ2tna29rb29vc3NzW1tbW19fX19fX19fY2Njb29vd3d3e3t7f39/f39/g4ODQ0dHW1tbi4uLDxMPo6OjDw8Pn6OjCwsLn5+fl5eXBwsHl5eXBwcHm5ua7u7ve39+kpKTY2Niam5uYmZmsra6kpqaWl5eEhYW/wMDS09PV1dWIiIiOj4+QkZGUlZWYmpqdnp6Vl5dGR0cFBQUAAAAAAAAAAAAAAAAnKCiwsbHY2NjZ2tra29rb29vW19fX19fY2dnZ2dna29vb3Nzc3Nzd3d3e3t7f39/Dw8PIyMjNzs7S09LW1tbd3t3g4ODm5ua9vr26u7q8vby/wMDExcXIycnMzc3P0NDR0tLV1ta9vb25urm5urrLzMzOz8/f4ODl5ubAwMDl5eXn5+e7vLzAwcDe39/k5OS7vLu4ubjU1dXe397j4+OhoqKnp6eur662t7a+v7/Q0dHS09OUlZWUlJWXmJiam5ygoaKoqaqtrq+vsbHLfy3qAAAAPXRSTlMAAAEJEhMOAzKGut/3/evLnlsIPP6pY9J97Jb+B7AgyjrkVNJueP79+PPw/eMzJWyew93v676ikHhaPScFrIhAuAAAAAlwSFlzAAAsSwAALEsBpT2WqQAAAAd0SU1FB+cFCg0nMw0Frq4AAAChSURBVBgZ7cE9agJRAIXR795588MkDIxaiZAqpQELIXuwyjKzj3RiNXsQJWBKkxHR95w06bIAz+HuH0RiJf2FgagULSRtlBwAjTXXqVBfKmH9CYRsqegg+oJKMlFwJaSpkv0jJrLr+oGoLIG29Qxw9g003PzsvDJgj7gQGDzlGLBDw6+WPD8bCM41kb6A6fb12GFAz3rT4F03HYgX/fEBXAGUHBiY2KaSnwAAAABJRU5ErkJggg=='
        }
      }
    },
    title: 'Wedding day flood',
    description: {
      raw: createRichText(
        "Kudos to SimpliSafe® for alerting me about water in my basement. I was out of town and getting ready for my daughter's wedding when I got the call. I was able to call a neighbor and have them take care of it. All was good and I could enjoy my daughter's special day!"
      )
    },
    author: 'Newlyblond1',
    listTitle: 'Water Sensor + Monitoring',
    list: [
      'Sensor sounds the alarm as soon as it comes into contact with water',
      'We call the names on your contact list to help ensure you’re notified'
    ],
    note: ''
  },
  {
    color: '#0F2648',
    backgroundImage: {
      description: 'Hitting the panic button',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=340&h=214&q=50&fm=webp 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=680&h=428&q=50&fm=webp 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=1360&h=856&q=50&fm=webp 1360w',
              sizes: '(min-width: 1360px) 1360px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=1360&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=340&h=214&q=50&fm=png 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=680&h=428&q=50&fm=png 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=1360&h=856&q=50&fm=png 1360w',
            sizes: '(min-width: 1360px) 1360px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1360,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAMAAACejr5sAAAC31BMVEUBAg07PUQBBBEBCRwbITWAW0KFYEaJYkyLZU9vYF+ObF6Mb2ReV11tXlnn6u56fI7HxdEwMjYBAgEFBgYBAw8BBBQCDSMYITp1VkeHXkOLYUeNZEpzYmGIY0yKaFV+bnGThIG5vcR8fpHt7POcnKQnJyU1OECVl7MBBBMBBxoDDycWHzQOFB4uLTloU1ONZE14ZmOFXkKGYkaHdnelkYnx8fJ+gZO9ucZlYm9KSFBZXnStr84ABRcBCh8CDyg1LjN8VztfRzU4MC0fJTVBQVZtVk2DXjyUhIHr5uj29fR9gJPl5OuAf45YVmRqboi3uNcBCBsCDCQEEiw5LilsSil6VC6HWzJoSzA3JhckIyc0NUNxbILo5em5vL8uNkbKytXGxc9jYXF0eZPBw98BCR4CDSQFEy8rJSBNMw1dOQlvQARkRiQTFRRdMgw6KRopHxNTSUwXHB4DCwxtcn/ExM55dYh1e5TGx+EBCB4BCiIDECwWEhY0GwBMKABpOQBjSjMOEBFFJgtOMyBRPCJDNiYPExgtM0dYX3hmaHt2dIp5gJjMzuUxIyJQMRF1Tyh5RwtpUj8ICAQfGxdWPzAyJhUcIy0TGh4FDAkJEAw0PUd2fZyiqcHP0OYCDikuKjVJODB5XEmBVCVaTEIjFwwMDxENEBQCBAICBQEHDgsQFxoIDg0HEA8NFxppb5Wztc0BDSYvJh9GLRFWNhdgPBVQRD9TQjQdFQoWEQgCAwIBBQIGDQsOFhkEDwsQGRxmbHYBCSAqHxVGJwZ4RxWtXgxfSzs3GwIvGgRJMBoQEAsDBgUBBAECBQICCQQEDwoIEA5MUVMBCB0ABxwlHBFCJwe1iz+7iC9kV0kXDgQxHAVmQyUZGRQFBwgNERUNExYKDRAGDgwIDw1RVVoBBxwACB0ACB4TEA0LBQFaQBxgPBlNR0QTDQcgEAFqQh8nKCUBAQEBAwICBQMDBwUIDQoMExIxOELqNNVnAAAAAnRSTlOxsTcr0dsAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQsQNQf4B32OAAAARklEQVQY02NkYGJEB+8YRRgxAQvdBN+IMghiqmRQxlTJwCAE50QtZ2T8IAAWFIALLgMyQWaxRKxAqPQ6CWU8QjLrrgqEBgCWzAqusov5sQAAAABJRU5ErkJggg=='
        }
      }
    },
    deviceImage: {
      description: 'Panic Button',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAAA/1BMVEUAAAC+v7/Cw8PHyMjOz8/U1dXb29vg4ODk5OTo6Ojr6+vx8fG9vr7u7u69vr7t7e3s7Oy9vr7r6+u7vLy0tbW/wMDGx8fMzc3S0tLX19fb29vd3d3c3NxUVFQAAAAAAAAAAAAAAAAAAADBwsLHx8jNzs7U1NTa2trg4ODk5OTZ2trm5ubGx8fExcXGx8bGxsbFxsbi4uLo6Ojr6+u7vLzDw8PT09PZ2dne3t7Hx8fn5+fq6uq1tbXLysnRzczY0tHe3NvOzs7l5eW1trbMzMvSz87Y1NO9vr6/wMDc3NzMzc3DxMTNzs3R0dHj4+Pf39/p6enBwcLAwcHS09PY2NgaJG2tAAAAI3RSTlMAZuby9PX19PPurgTdN+ZAQNs1XOXp6uvr6unnqAgFBAMCAUuDjoUAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQoNJzMNBa6uAAAAdElEQVQYGeXBMQrCQBRF0ftePjhgpwSrbMH9r8ZCsQiCaGGhTcQkM0xwCTmHdVDlxSy2B83OZGGSJiYLSJpQBCSN3hQBG0kfagGhu3Z6iMJwbYY2goqBQTcDIjOjnmdLYY78MYIOLlTMT8eCGzKTaa+lE/AFX9gOjJIWhcYAAAAASUVORK5CYII='
        }
      }
    },
    title: 'Hitting the panic button',
    description: {
      raw: createRichText(
        "Someone decided to attack as soon as I shut the door. My heart is so filled with gratitude that my security system's panic button was right near me. I can't stress how much I love SimpliSafe® Home Security. They dispatched police and talked me through my major panic while I hid."
      )
    },
    author: 'Eryn O.',
    listTitle: 'Panic Button + Monitoring',
    list: [
      'Call for help immediately',
      'Monitoring agents request police and medical dispatch 24/7'
    ],
    note: ''
  },
  {
    color: '#C84A3B',
    backgroundImage: {
      description: 'Pizza rolls fiasco',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACxFBMVEU/REdITlFVXF1lamZ2fHaFiYUODg80KyeZfmjs2LTx3b/y49Dx6t3m5OHt7+7Z3N5uc3hSU1KhpaJ6f3w+QUJITExXW1lqb2p4e3aPkY0pKio/NjOLcmHiz7nv4dLr39Lz697p4tvt7Ond396fpKiJi4jHy8iMkIw6OzlHSUZXWlZvc256e3WWlpAZFRQyJiKAW0jQs5vy4Mfo0r/x3MT569Tu6OHIw74vLCtsa2eho6Bwcm82NjFERT9YW1ZzeHJ7fHacnJYkHRkeGhpIOza7lnXt17Hx37z03rz458zz6dqekYksKSmDfne+v7t6e3c0NC5CQjxaXVd8gHtubWe6vbldX194fX2Wn56NlZSMlZaepaW2u7u/x8q0vcCiq692e31fYWKCiIZLTko0NS9DQjxfYVqJjYe3u7bU1tM6ODQrKSgyKiaBc1ZmTjd1VjyJdVSOe2GPgGaHdV9JQD1BQkJrcW89QD40NjBERT5jZV2SlpDBxcHb3dpMSUI9JRb57KHw14Tem0v1xGn53Yf67qvuv3tPNylKS0hbX1o1NjE1NjNHSUNnamGXm5WipZ55eHFQS0E6NzQcFBNfQx5qUiNcOx2GVSSWcC6afkeCWjA+MShQUE1aXlo2NzI2ODVJTUhscGiChHs8OTAvKiNPSkBTT0gTERISEBIQDxEUEhMTEhQSEhQODhE4Mi1PT0xQU08qKic1ODVJT0tudG6ZnZZ5enBnZF1dWU+iopm5urajoZubnJnEyMnAxMWorK5rbGxCQDxISENFRkMlJCMzNjRHTUprc22GioGxta/d3tlQTkZEQ0FMSkk1MzFPTk1WVFZVVFZPTlJDQ0YsLC0iIiQ7PTo8PTokIyEeIB4nKic8QDtDRDxiY1qAgXcvKR4lIBgnIhkkHRUtJyAtKCInIx0gHBcZFA8UEA8kIiAiIiEWFhYlcuHrAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAAChJREFUCNdjZGDEAkSwiLHQUPCtMLKgMBZHoGhnQFOJWxCb9oMOUAYAc9ED9iYUN0UAAAAASUVORK5CYII='
        }
      }
    },
    deviceImage: {
      description: 'Smoke Alarm',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABv1BMVEUAAADo6Ojb29vU1NPY2Nja2trc3Nze3t7f39/h4eHi4uK3t7bR0dDV1dXj4+Pk5OTl5eWxsrHNzs3l5eXm5ubGxsXm5ua9vbzExcPn5+fAwL/n5+e/wL7n5+e/wL7o6Oi+v73n5+e9vrzl5eXm5ua8vbvk5OS4ube8vbvj4+O6u7ng4OCxsbC3uLbQ0NDf39+6u7m7vLq+v73W19bZ2dnb29u6u7nAwb/ExMPHyMfLy8vPz8/S0tLU1NTX19fZ2dnb29vd3d3f39/h4eHQ0NDS09LV1dXZ2tnc3Nze3t7g4ODi4uLk5OTKy8rNzc3T09PW1tXY2Nja29rj4+Pl5eXIyMfLy8vOz87S0tHU1dTm5ubDxMLGx8bKysrNzs3R0dHU1NTW1tbCw8HFxsXJycnV1tXa2trCwsHFxcTIycjMzMzQ0M/W19bn5+fBwcDExMPHx8fDw8LPz8/AwL/GxsV7e3mRkZBnZ2Vubm1/f352dnTf4N++v73ExcO0tbOcnZunqKaam5mCg4Gdnpx4eXd7fHq/v768vLujpKJvb25XV1W6urm/wL7S0tLT1NPU1NOmpqWKi4q+vr3JysnPz877VONwAAAAPnRSTlMAAAARbK/R1LZ3GwFt8fiGBQGaugh5nR33OoKnyOzqEOwSzPABia8l+0WIrQSuyQ8CgPn8mwsee7/g48aJKjWnGpEAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQoNJzMNBa6uAAABAElEQVQYGd3BPUtCYRgG4Pt+3i/PKaOkTzoIIhk0WUNEW2st/cWWpta2toYylyBIjIgMDOIYSli8KWbv6fyLrgv/DhEo5r6QIzBDbjPTJAfIEAvcIX3EzBV7CBSK+3Ycx9YOJ5PZyssnArUiVRMb8zbVExa40RsC0OqAmeoTI3paQSDinBt0+7pU8x6AEICIMSbZRGe97eDd6LgMQIsjX+3iXFpeatHzXQBoMf1SsUQm5HJyi4YCoFU0jmL+qV3IvHoAIHK22m3duw+bGnM49QqBtIVb33vXj7bi3M3RcxuBQE7tmk2iaUOfpyf6BwGBOlkfjVjgHdlEhgh2mbtE7hdXP0IwzWxBkgAAAABJRU5ErkJggg=='
        }
      }
    },
    title: 'Pizza rolls fiasco',
    description: {
      raw: createRichText(
        'I fell asleep one night with pizza rolls in the oven. A fire alarm ensued and the SimpliSafe® system possibly saved my life. I awoke to the smoke alarm, and a phone call from SimpliSafe®. I can tell you how blessed we are to have you as our protectors when I cannot be.'
      )
    },
    author: 'Anonymous',
    listTitle: 'Smoke Alarm + Monitoring',
    list: [
      '24/7 protection that never sleeps',
      'Monitoring agents call for emergency dispatch of fire trucks and ambulances'
    ],
    note: ''
  },
  {
    color: '#00565C',
    backgroundImage: {
      description: 'Ping-pong sized hail',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACiFBMVEXT08/S0s3JycTS0cq9vrtTVlgqKSkoJyclJSUiIiJcW1nFxb66v7zW1Mvq5t2zsKVpaWBobGiEhH/GxsDExL/Dwr6+vbfHx8Hq5t5oZGAjIyMgICAeHh5MS0m/vrbf3tjy7+na1s26tquuraBzdGt5d3C7u7XPz8rQz8rKycTNzcbt6uJoZ2QfHx8dHR0aGhoZGRmioJnT08zz8evV1MvJx7zAvrKZmI9ubWiqq6XS087S0c3R0MzIyMHY0cUtLS0YGBkWFhYUFBQTExQVFRV1dHDLy8Ps6ePv6+Ooq6VVXF42Oz6IiITAwLu/vrnDw7zb2M8yMjIREREQDw8UFBVvcW/JycLFxLzW08isrqZlbW1ES08wNDZdXl3S0s7T0858g4MlKCwREBAPDg4NCwwLCAgVFBNeX17ExL7Y1s3DwbU9Q0YxNTcyNzpOUVLT1M/U08/JyMJ1ensMCgsPDQwOCwoMCAcMCAYOCQdsZl6zsqzU08upq6FPV1o/RUk3PD5ER0VtbWbDxL/Bwr3BwbyFh4QlISATDgsPCgcNCAYNCQcQCghVTEWYmpeMkIpgZ2VdYV5wc2qEhXpUVlHP0MvT0s7Fxb+fn5lgXlgaFRIQCwgNCggPCwgWExGan528vbWio5qOk4uBhHxhZ2NOVFTT1NC1tKt3c2ZAPjoeGhgVExApKCVka2uXm5jAwLrR0Mi+v7egpaCnqaKSlZGNkpDR0s/Nzsm3uLF/gXuEh4OKi4aSlZCxrqWtsKq1t7K3t7G6urS0s6yysqy1ta62trC4ubS6vLi+v7rCw76+v7mwsaqys62ys6yztK21tK64uLK7urW8vLbCwbvMy8bQ0MvOz8rOz8vP0MycClifAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAAHNJREFUCNdjZGDEAkTA5HshZDEWYbjUfyY0QRi4aACmfBlBpn4UYEQynEXwrsqaUEZBGP+BIojM/yCIYTmLsAjjpHwY74ksRFAod0oDiHFPGSQI1bX0FMPkJUD6Cy+SdoH3EaIgebjrQa44hmrHAQZHRkYARcYWt5iuHe4AAAAASUVORK5CYII='
        }
      }
    },
    deviceImage: {
      description: 'Glassbreak Sensor',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABAlBMVEUAAAC+vr69vb2+vr7CwsLJycnPz8/V1dXZ2dnd3d3g4ODi4uLk5OTm5ubo6Ojp6em9vr3p6em9vr7p6enp6eno6Oi9vr7o6Oi+vr69vr6+vr7o6Oi+vr7o6Oi+vr7n5+etra2jo6Ovr6+4uLjCwsLKysrR0dHV1dXZ2dnc3Nzd3d3c3NzZ2dnT09PLy8sAAAAAAAAAAAAAAAC9vb3BwcHIyMjPz8/U1NTY2Njc3Nzg4ODi4uLl5eXm5ubo6Ojk5OTf39/R0dHV1dXW1tba2trQ0NDT09Pn5+fExMTMzMzKysrHx8fb29vd3d2hoaHh4eHFxcXJycnOzs7X19fj4+Pe3t5GFrFhAAAAM3RSTlMAT8HR1dfZ2tra2dfV0cJT1tvm7Ovr5erl5OTp4+jL0T/M2+Ln7O7v7uvm39fBRggHBgSDw6o4AAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUKDSczDQWurgAAAJhJREFUGBnlwUEKglAUhtHv/j4Dc2Dgc1KToP2vpi00yiZhFIVp3kqjttA5/Af7cWQQig32scVJTBIQToxmTITLmc8DA8OJweJchFOXQcaTgNjYPSn7rgXDKUASq0u6D0QQLgjSllm9aLiKF3Wwq/trzKwKvCkBlpe8P0QxkgG3ojmUDfQEnMTTCqhAOAlXQMVAOFvbN8zsATcLHlJ9qBt+AAAAAElFTkSuQmCC'
        }
      }
    },
    title: 'Ping-pong sized hail',
    description: {
      raw: createRichText(
        'I was at a funeral when the ping-pong sized hail and rain hit. I got a text notification on my phone that my hallway glassbreak sensor went off. I called my husband (who is getting a call from the monitoring service at the same time) to come home ASAP.'
      )
    },
    author: 'Caprice A.',
    listTitle: 'Glassbreak + Monitoring',
    list: [
      'Glassbreak Sensor detects the distinct sound of breaking glass',
      'Monitoring agents alert you and, if necessary, call the police'
    ],
    note: ''
  },
  {
    color: '#C84A3B',
    backgroundImage: {
      description: 'Wine, On Time',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=340&h=214&q=50&fm=webp 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=680&h=428&q=50&fm=webp 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=1360&h=856&q=50&fm=webp 1360w',
              sizes: '(min-width: 1360px) 1360px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=1360&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=340&h=214&q=50&fm=png 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=680&h=428&q=50&fm=png 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=1360&h=856&q=50&fm=png 1360w',
            sizes: '(min-width: 1360px) 1360px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1360,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAAsFJREFUGBkFwVtv21QAAOBz893Oxc2SJk3XFpJ2WotIEBKCISEmBGJPk/bf9sYr2h+YxAPS4AEhnlhXAVtXbU22LnFrx/dj+/j4HL4PPv7p54cPvuMlg0hRVAMhJCGUUhYJxQrSbVMzdSBkHoSbgj799beld7XxvDuzw6P9Yzz//Phi9dZSVCIkzShgPA+DMglZHuXxZr26fvJs4ZRhUcTni8UqCYtKcCg3WfzJ5C4pGes6I1Y3YRhoCpKNIWRdVhGvkaoaRVL4y/iyM7jdw+8vX8ar4Gb1wd3tOb1hnnwgTS1caxshZHeRbDitmG6Str2jqsYfp8vTvxfT6lJXv7hOZFlkIn7d1TpDtQWYwEwQgaiuR7ap/fPqXZ5yKUWv6zQcfP3V91lw/tfzxQssJ+z8/jc7akvXd0ZZwlNMLaXcJBUJE+/Vm+ftoeNn1Wadxt4Gy+p4Ovevr9ukzrwbKti3s65O7AYgLmAPyC0CKwQqoyGuubVO8w20klBEQarYtiY0TTNLWrlKbcJqvGPutmUVrePX/6Y0U+1OW2JHaf3y8hlxHIvW8vLsAiFcJ3lnAFvucG//QCL4+4vlj/eO0ordPZlf+Z6iQ+IndZgx7JiC+Fc+CVkSlaAWUDIkOC1uQGd7KiUEotm7xVuKfnj4maEqjmq/O7ugPNUMNwiuyrrYC/p49OkYrOu3f/6XeYGBrflsdnI4NVQdShAGy26/Z2LudLcdy7L7gzxKMz/gGNOc60jB2sju7fUn7fH8ZPbw0aM704llWAgTIURE65ZtqqoEgq/fnEFN2T+euaPbkZ8iCA2dEAI0yeT9Bz/sb+8KQBACWFEYaxBCjuMynpWsuXl/miXBrY+/5Gpra6zeG4yCxBMVJxhJmxuT8UFVcyA4hKSgFCEMIRj03E0sojLnXHaHR5rdz7MkztKqpo7Z2vro4H+on4zw7MMsUwAAAABJRU5ErkJggg=='
        }
      }
    },
    deviceImage: {
      description: 'Video Doorbell',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABcVBMVEUAAACkpaXr7Ovv8O/d397n6Ofc3dzt7e3y8vLW2Nfa2trs7ez29vbd397y8vLf4eDv8O/f4eDv8O/f4N/v7+/v7+7u7+7e4N/u7u7t7u3e397d397t7e3P0tHt7ezAxcTs7ezCxcTt7u2/wsC7xMXk5+fs7ezAwsHMzs3N1tfa3+Dk5eTn5+bV1tVPT08VFRUZGhp0dHRzdHQXFxcSEhIWFhYZGRqsrKxLS0seHh4TExMYGBgbHByCg4J5eXkXGBgUFRUWFxeur67f4N9eXl4cHR0hIiKBgYHq6+ro6ejh4eHl5uXq6upfX1+LjIvp6ulnZ2eQkZDf39/q6uno6Ojp6enn6Ofp6ejJycjIyMbHx8XU1dPY4OG0w8e7yczg5eXH09WepKaSk5WUlZefqazb4uOisLSnqKmZmpyhoqS8vb6/zM+ktLi2t7inqKqrrK2sra6wv8OkvMCsra+Wl5ilpqi6zNGerrKXnaCWn6Kww8jF7bAGAAAALXRSTlMAABYPQ9f9uSAx+dwLpmLLiMyKzYqKis2Kis3Nis2KyIWSTxrmuwIcntrSfwin8CuMAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUKDSczDQWurgAAAKNJREFUKM+l0T0KAkEMBeD3kkzWdRUURC8gbOHhBc9i5R1sdMUfZLGx2ETEwmGqjzdheAH+PRRNIjDbaDWKsZprkgd2A9S6VVVdHe8DNHGSpISZ4n4ppQQ0KVzmpMFB8pm+BKgq+ImwiAZIjyo9b3B15JmC9w0zG5w8FmKAzcgHE/ZnYBKTCl+Qt4gck6QrfrX0Hbtp7nNnNi9bCztCS5LcD/EFFfQTKjCfVEoAAAAASUVORK5CYII='
        }
      }
    },
    title: 'Wine, On Time',
    description: {
      raw: createRichText(
        "Honestly the number one reason I have a SimpliSafe® alarm system is so I can talk to the delivery person through the video doorbell and tell them to leave my wine while I'm at work."
      )
    },
    author: 'Alexia Loannou',
    listTitle: 'Doorbell + Monitoring',
    list: [
      'Built-in motion sensor sends a notification and captures video (with a monitoring plan) when anyone approaches',
      'During an alarm, monitoring agents can view the recordings to verify activity when requesting police dispatch*'
    ],
    note: '*Requires select professional monitoring plans with video verification.'
  }
]

export const usData = [
  {
    color: '#00565C',
    backgroundImage: {
      description: 'Help prevent crime',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=293&h=183&q=50&fm=webp 293w,\nhttps://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=585&h=365&q=50&fm=webp 585w,\nhttps://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=1170&h=730&q=50&fm=webp 1170w',
              sizes: '(min-width: 1170px) 1170px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=1170&h=730&fl=progressive&q=50&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=293&h=183&fl=progressive&q=50&fm=jpg 293w,\nhttps://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=585&h=365&fl=progressive&q=50&fm=jpg 585w,\nhttps://images.ctfassets.net/v6awd0kabi65/454JBJi6wedxqntlJwxoJA/0e7f0147bb105f6b1a7db4cdb1b73522/SavedDay_preventCrime.jpg?w=1170&h=730&fl=progressive&q=50&fm=jpg 1170w',
            sizes: '(min-width: 1170px) 1170px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1170,
        height: 730,
        placeholder: {
          fallback:
            'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAMABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABgUE/8QAJRAAAQMDAwMFAAAAAAAAAAAAAQIDBAARIQUSIiMxQVGRobHh/8QAFwEBAQEBAAAAAAAAAAAAAAAAAwIAAf/EABwRAAMBAAIDAAAAAAAAAAAAAAABEQIDEiJBUf/aAAwDAQACEQMRAD8AEROnKaUshICxfPi+a6jCRGoR2dO2vqCwdzfHz6Ee/wAUD45yX0L38YHXVNqcUrkncbgDNhTz6HS/qmlw4sMFpnkVAbiok/dJrKSMtNuEVtaos4ho2BFs57j9odIumMEiqRB//9k='
        }
      }
    },
    deviceImage: {
      description: 'Outdoor Camera Series 2',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=712&h=940&q=50&fm=webp 712w,\nhttps://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=1424&h=1880&q=50&fm=webp 1424w',
              sizes: '(min-width: 1424px) 1424px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=1424&h=1880&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=712&h=940&q=50&fm=png 712w,\nhttps://images.ctfassets.net/v6awd0kabi65/66md40vkBYjgeec9MXEpHJ/fe6e41023f78154826f3370bcb15c540/Olympus__1_.png?w=1424&h=1880&q=50&fm=png 1424w',
            sizes: '(min-width: 1424px) 1424px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1424,
        height: 1880,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABtlBMVEUAAAC5uLgPDw8REREpKSnc2trEwsORj5CDgoOLiYqtrKzRz9DRz892dXVgX2Cwrq/T0dHT0dJXV1etq6vm5OR1dHRWVVXNy8vJyMiop6eLiorV09R5d3jIx8d+fX26uLmjoaGpp6jg3t6fnZ2Uk5OqqKmDgoLBv7+ysLB9fHw4Nzfx7u5tbGzv7e1lZGXv7OyjoaLu6+yrqarh39/r6enh3997enrZ19fg3t7e3d3e3Nze3Nzd29vNy8w0MzQtLC0uLS4xMDExMTEyMTIzMzUwLzAvLi8/Pj87OztBQEE7OjtGSU1RVV8vLy86OjpNTU1QUFBFRUUwMDEwMDIrKitBQUFUVFRVVVVPT09AQEAzMjOFhIRCQkJLS0tWVVVKSUpGRUV3dXY9PT1GRkY5ODksKyyCgYEuLi46OTo5OTk2NTYqKSqjoaJbWlqdnJw8OzxOTU66uLnl4+Ognp9xb3BkY2R3dnatq6zg3t/a2Nns6erm5OTj4eHi4OHj4eLe3N3q6Oji4ODh3+De3Nzb2dnr6enNy8vMysrLycrJx8jHxcXRz8/k4uKysLGura2sq6usqqvGxMTepR7SAAAAPnRSTlMAAAAAAAplrMm9hyY+4vt/AT73hQri/TNmva9Dz5vHuJecOv4+o48K2pECs4OcmYRRaTwm+eAERKq6vb+oLlYItdwAAAAJcEhZcwAAFxEAABcRAcom8z8AAAAHdElNRQfoBxYRDABAwGFXAAAA2UlEQVQYGdXBMUvDUBiG0ef97neTDKVQKChm0E1QQYsgzv5bp24ujq66CSIoODh0UVSoFGJ6TW/ij/Ac/gkBUdmSP4KRZhK6kz7oiYlOfqJal27f6NnUz8pRVaUqpbBFL4zPPVvB/uuSzKzMVsDXxQ6ZWWmKMdZN04wLI3MryvfP+ikBExmZW9S2HhKdQkbm9r2gSWzYVSDzl1DTO1B4JrO1LY6ngqNZde0tmfN4GHb3tBHu6TlYJQkhY+BghdS61skYOFiUCnWMgYHNy04sLp2BwE/VQbph8AsUKyyCTdqU/QAAAABJRU5ErkJggg=='
        }
      }
    },
    title: 'Help prevent crime',
    description: {
      raw: createRichText(
        'Somebody was on my property and the agent took care of it then and there. They deterred them from stealing something or breaking in. I’m glad I had it because who knows what would have happened.'
      )
    },
    author: 'Anonymous',
    listTitle: 'Outdoor Camera + Pro Monitoring',
    list: [
      'Advanced AI identifies a possible threat on your property',
      'Live security agents intervene to deter intruders and help prevent crime from happening'
    ]
  },
  {
    color: '#006FEE',
    backgroundImage: {
      description: 'Wedding day flood ',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6pKPtQH7L23AEFiN4wkRiE/91222c5b27ffb3efd72b535f29d2287c/image_163.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACx1BMVEUnLT0wOEkoLjspLjspLz0uNEIyN0Y1PEs6QVE8Q1M7QFBARlZHS1tARFU8QVJAR1k7Q1Y4QVU1P1MxO081Pk82P1BYZXlhboJndYlaZ3pbZ3pygJNyf5NwfpFhbYA2P1MkLUIlL0UhKT0iKz4wOEtATGNGVG08SmFSX3MpMD5VYXRndYpseY1gbH9cZ3p3hJZzgZRwfZBHUWMzPVAoMUUfJzodJTccJDYdIzQgJjU5RVs/TWYuNEExOEdgbIBlcodteo5fa31cZ3l1gpVzgJN1gpRueoxoc4VkboBmboBXXnBKUWRKTVwsM0QlKzo5RVkaHyofJC9NV2Y9RlZFTl0/R1RNVmZbZnZLVWVHUWA2PEtRVWNzbnZQUmFLTl5pXWQ0MTstMD4pLTskKTYZHSkbICsjKDMjKDImKzUnLDUrLzosMTs3O0U4PUc0OUNmXmF0XVqFcHI2O0lXSVFCOUMoLTsjKDUhJjQbHyweIi4eIy4iJjAiJzEjJzMlKjUyNUAyNkGPhIVcR0mGZWNtZm1bUlqlo6pCRlIkKTUfIzAaHSgcHyofISwlJC8rKTMtKzQzLzk3Mz08N0E2Mz5IRE6qnqCehoSolJKxoaWllJhdXG1PTlstKzUrKTQhISwiISwjIiwsKTIxKzQ2LzhDNz5CNz9HO0NFO0KCd3tzZGplWmF7aXiAcXqViZRGRVU4NEAvKjMtKTIdHikfHyohISspJzEtKjMyLTY2LjY6Mzs7NDw+Nj+yqKlpWF5zXmI4M0I2NEBoYm47O0s4ND4xLDUyLjgaHSkcHyseHysgICsjIy4kJC8mJS8tKjUwLTdPSlPWzMu2q6xcVFpAPkc8OUN3cn45O05HRlQvLDceIiogJCwjJy4nKzApLTAsMDMrLzIvMjQxNDWRjY3WzcuIgYZTUl1WVFxwbW1VVGAuMkMyNTwuMTIsLzGdP+LcAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAACdJREFUCNdjZGDEAkSwiLHQRPANVPiNKEJQSBjTGRRZdNSG8SYWlQAR9gXMDX4g0QAAAABJRU5ErkJggg=='
        }
      }
    },
    deviceImage: {
      description: 'Water Sensor',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/1mL6BBJPClb968ilhokSoC/7274ba25c28d47f2d580a4b95ea4b6f7/Water_Sensor.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABfVBMVEUAAAD////Z2dnZ2dnZ2tna29rb29vc3NzW1tbW19fX19fX19fY2Njb29vd3d3e3t7f39/f39/g4ODQ0dHW1tbi4uLDxMPo6OjDw8Pn6OjCwsLn5+fl5eXBwsHl5eXBwcHm5ua7u7ve39+kpKTY2Niam5uYmZmsra6kpqaWl5eEhYW/wMDS09PV1dWIiIiOj4+QkZGUlZWYmpqdnp6Vl5dGR0cFBQUAAAAAAAAAAAAAAAAnKCiwsbHY2NjZ2tra29rb29vW19fX19fY2dnZ2dna29vb3Nzc3Nzd3d3e3t7f39/Dw8PIyMjNzs7S09LW1tbd3t3g4ODm5ua9vr26u7q8vby/wMDExcXIycnMzc3P0NDR0tLV1ta9vb25urm5urrLzMzOz8/f4ODl5ubAwMDl5eXn5+e7vLzAwcDe39/k5OS7vLu4ubjU1dXe397j4+OhoqKnp6eur662t7a+v7/Q0dHS09OUlZWUlJWXmJiam5ygoaKoqaqtrq+vsbHLfy3qAAAAPXRSTlMAAAEJEhMOAzKGut/3/evLnlsIPP6pY9J97Jb+B7AgyjrkVNJueP79+PPw/eMzJWyew93v676ikHhaPScFrIhAuAAAAAlwSFlzAAAsSwAALEsBpT2WqQAAAAd0SU1FB+cFCg0nMw0Frq4AAAChSURBVBgZ7cE9agJRAIXR795588MkDIxaiZAqpQELIXuwyjKzj3RiNXsQJWBKkxHR95w06bIAz+HuH0RiJf2FgagULSRtlBwAjTXXqVBfKmH9CYRsqegg+oJKMlFwJaSpkv0jJrLr+oGoLIG29Qxw9g003PzsvDJgj7gQGDzlGLBDw6+WPD8bCM41kb6A6fb12GFAz3rT4F03HYgX/fEBXAGUHBiY2KaSnwAAAABJRU5ErkJggg=='
        }
      }
    },
    title: 'Wedding day flood',
    description: {
      raw: createRichText(
        "Kudos to SimpliSafe® for alerting me about water in my basement. I was out of town and getting ready for my daughter's wedding when I got the call. I was able to call a neighbor and have them take care of it. All was good and I could enjoy my daughter's special day!"
      )
    },
    author: 'Newlyblond1',
    listTitle: 'Water Sensor + Core Monitoring',
    list: [
      'Sensor sounds the alarm as soon as it comes into contact with water',
      'We call the names on your contact list to help ensure you’re notified'
    ],
    note: ''
  },
  {
    color: '#0F2648',
    backgroundImage: {
      description: 'Hitting the panic button',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=340&h=214&q=50&fm=webp 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=680&h=428&q=50&fm=webp 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=1360&h=856&q=50&fm=webp 1360w',
              sizes: '(min-width: 1360px) 1360px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=1360&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=340&h=214&q=50&fm=png 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=680&h=428&q=50&fm=png 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/7jv3LXBflLOQ2D43VdoxcK/f3b1b336a49b4618390677777e21bb40/image_118.png?w=1360&h=856&q=50&fm=png 1360w',
            sizes: '(min-width: 1360px) 1360px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1360,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAMAAACejr5sAAAC31BMVEUBAg07PUQBBBEBCRwbITWAW0KFYEaJYkyLZU9vYF+ObF6Mb2ReV11tXlnn6u56fI7HxdEwMjYBAgEFBgYBAw8BBBQCDSMYITp1VkeHXkOLYUeNZEpzYmGIY0yKaFV+bnGThIG5vcR8fpHt7POcnKQnJyU1OECVl7MBBBMBBxoDDycWHzQOFB4uLTloU1ONZE14ZmOFXkKGYkaHdnelkYnx8fJ+gZO9ucZlYm9KSFBZXnStr84ABRcBCh8CDyg1LjN8VztfRzU4MC0fJTVBQVZtVk2DXjyUhIHr5uj29fR9gJPl5OuAf45YVmRqboi3uNcBCBsCDCQEEiw5LilsSil6VC6HWzJoSzA3JhckIyc0NUNxbILo5em5vL8uNkbKytXGxc9jYXF0eZPBw98BCR4CDSQFEy8rJSBNMw1dOQlvQARkRiQTFRRdMgw6KRopHxNTSUwXHB4DCwxtcn/ExM55dYh1e5TGx+EBCB4BCiIDECwWEhY0GwBMKABpOQBjSjMOEBFFJgtOMyBRPCJDNiYPExgtM0dYX3hmaHt2dIp5gJjMzuUxIyJQMRF1Tyh5RwtpUj8ICAQfGxdWPzAyJhUcIy0TGh4FDAkJEAw0PUd2fZyiqcHP0OYCDikuKjVJODB5XEmBVCVaTEIjFwwMDxENEBQCBAICBQEHDgsQFxoIDg0HEA8NFxppb5Wztc0BDSYvJh9GLRFWNhdgPBVQRD9TQjQdFQoWEQgCAwIBBQIGDQsOFhkEDwsQGRxmbHYBCSAqHxVGJwZ4RxWtXgxfSzs3GwIvGgRJMBoQEAsDBgUBBAECBQICCQQEDwoIEA5MUVMBCB0ABxwlHBFCJwe1iz+7iC9kV0kXDgQxHAVmQyUZGRQFBwgNERUNExYKDRAGDgwIDw1RVVoBBxwACB0ACB4TEA0LBQFaQBxgPBlNR0QTDQcgEAFqQh8nKCUBAQEBAwICBQMDBwUIDQoMExIxOELqNNVnAAAAAnRSTlOxsTcr0dsAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQsQNQf4B32OAAAARklEQVQY02NkYGJEB+8YRRgxAQvdBN+IMghiqmRQxlTJwCAE50QtZ2T8IAAWFIALLgMyQWaxRKxAqPQ6CWU8QjLrrgqEBgCWzAqusov5sQAAAABJRU5ErkJggg=='
        }
      }
    },
    deviceImage: {
      description: 'Panic Button',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/3FC36QJsip4tAVuVAp0rRt/e1050afbaa45da9faaa1280980733c6e/Panic_Button.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAAA/1BMVEUAAAC+v7/Cw8PHyMjOz8/U1dXb29vg4ODk5OTo6Ojr6+vx8fG9vr7u7u69vr7t7e3s7Oy9vr7r6+u7vLy0tbW/wMDGx8fMzc3S0tLX19fb29vd3d3c3NxUVFQAAAAAAAAAAAAAAAAAAADBwsLHx8jNzs7U1NTa2trg4ODk5OTZ2trm5ubGx8fExcXGx8bGxsbFxsbi4uLo6Ojr6+u7vLzDw8PT09PZ2dne3t7Hx8fn5+fq6uq1tbXLysnRzczY0tHe3NvOzs7l5eW1trbMzMvSz87Y1NO9vr6/wMDc3NzMzc3DxMTNzs3R0dHj4+Pf39/p6enBwcLAwcHS09PY2NgaJG2tAAAAI3RSTlMAZuby9PX19PPurgTdN+ZAQNs1XOXp6uvr6unnqAgFBAMCAUuDjoUAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQoNJzMNBa6uAAAAdElEQVQYGeXBMQrCQBRF0ftePjhgpwSrbMH9r8ZCsQiCaGGhTcQkM0xwCTmHdVDlxSy2B83OZGGSJiYLSJpQBCSN3hQBG0kfagGhu3Z6iMJwbYY2goqBQTcDIjOjnmdLYY78MYIOLlTMT8eCGzKTaa+lE/AFX9gOjJIWhcYAAAAASUVORK5CYII='
        }
      }
    },
    title: 'Hitting the panic button',
    description: {
      raw: createRichText(
        "Someone decided to attack as soon as I shut the door. My heart is so filled with gratitude that my security system's panic button was right near me. I can't stress how much I love SimpliSafe® Home Security. They dispatched police and talked me through my major panic while I hid."
      )
    },
    author: 'Eryn O.',
    listTitle: 'Panic Button + Monitoring',
    list: [
      'Call for help immediately',
      'Monitoring agents request police and medical dispatch 24/7'
    ],
    note: ''
  },
  {
    color: '#C84A3B',
    backgroundImage: {
      description: 'Pizza rolls fiasco',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/6Unve0CS3kzToZhQaIJYgs/17db5c1ebf864eb0301b4d2391ca0a49/image_163__1_.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACxFBMVEU/REdITlFVXF1lamZ2fHaFiYUODg80KyeZfmjs2LTx3b/y49Dx6t3m5OHt7+7Z3N5uc3hSU1KhpaJ6f3w+QUJITExXW1lqb2p4e3aPkY0pKio/NjOLcmHiz7nv4dLr39Lz697p4tvt7Ond396fpKiJi4jHy8iMkIw6OzlHSUZXWlZvc256e3WWlpAZFRQyJiKAW0jQs5vy4Mfo0r/x3MT569Tu6OHIw74vLCtsa2eho6Bwcm82NjFERT9YW1ZzeHJ7fHacnJYkHRkeGhpIOza7lnXt17Hx37z03rz458zz6dqekYksKSmDfne+v7t6e3c0NC5CQjxaXVd8gHtubWe6vbldX194fX2Wn56NlZSMlZaepaW2u7u/x8q0vcCiq692e31fYWKCiIZLTko0NS9DQjxfYVqJjYe3u7bU1tM6ODQrKSgyKiaBc1ZmTjd1VjyJdVSOe2GPgGaHdV9JQD1BQkJrcW89QD40NjBERT5jZV2SlpDBxcHb3dpMSUI9JRb57KHw14Tem0v1xGn53Yf67qvuv3tPNylKS0hbX1o1NjE1NjNHSUNnamGXm5WipZ55eHFQS0E6NzQcFBNfQx5qUiNcOx2GVSSWcC6afkeCWjA+MShQUE1aXlo2NzI2ODVJTUhscGiChHs8OTAvKiNPSkBTT0gTERISEBIQDxEUEhMTEhQSEhQODhE4Mi1PT0xQU08qKic1ODVJT0tudG6ZnZZ5enBnZF1dWU+iopm5urajoZubnJnEyMnAxMWorK5rbGxCQDxISENFRkMlJCMzNjRHTUprc22GioGxta/d3tlQTkZEQ0FMSkk1MzFPTk1WVFZVVFZPTlJDQ0YsLC0iIiQ7PTo8PTokIyEeIB4nKic8QDtDRDxiY1qAgXcvKR4lIBgnIhkkHRUtJyAtKCInIx0gHBcZFA8UEA8kIiAiIiEWFhYlcuHrAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAAChJREFUCNdjZGDEAkSwiLHQUPCtMLKgMBZHoGhnQFOJWxCb9oMOUAYAc9ED9iYUN0UAAAAASUVORK5CYII='
        }
      }
    },
    deviceImage: {
      description: 'Smoke Alarm',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/50bSFBLIbPjX8HTiVxd6E4/cd545acdf121de109862d623fd6ff039/Smoke.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABv1BMVEUAAADo6Ojb29vU1NPY2Nja2trc3Nze3t7f39/h4eHi4uK3t7bR0dDV1dXj4+Pk5OTl5eWxsrHNzs3l5eXm5ubGxsXm5ua9vbzExcPn5+fAwL/n5+e/wL7n5+e/wL7o6Oi+v73n5+e9vrzl5eXm5ua8vbvk5OS4ube8vbvj4+O6u7ng4OCxsbC3uLbQ0NDf39+6u7m7vLq+v73W19bZ2dnb29u6u7nAwb/ExMPHyMfLy8vPz8/S0tLU1NTX19fZ2dnb29vd3d3f39/h4eHQ0NDS09LV1dXZ2tnc3Nze3t7g4ODi4uLk5OTKy8rNzc3T09PW1tXY2Nja29rj4+Pl5eXIyMfLy8vOz87S0tHU1dTm5ubDxMLGx8bKysrNzs3R0dHU1NTW1tbCw8HFxsXJycnV1tXa2trCwsHFxcTIycjMzMzQ0M/W19bn5+fBwcDExMPHx8fDw8LPz8/AwL/GxsV7e3mRkZBnZ2Vubm1/f352dnTf4N++v73ExcO0tbOcnZunqKaam5mCg4Gdnpx4eXd7fHq/v768vLujpKJvb25XV1W6urm/wL7S0tLT1NPU1NOmpqWKi4q+vr3JysnPz877VONwAAAAPnRSTlMAAAARbK/R1LZ3GwFt8fiGBQGaugh5nR33OoKnyOzqEOwSzPABia8l+0WIrQSuyQ8CgPn8mwsee7/g48aJKjWnGpEAAAAJcEhZcwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQoNJzMNBa6uAAABAElEQVQYGd3BPUtCYRgG4Pt+3i/PKaOkTzoIIhk0WUNEW2st/cWWpta2toYylyBIjIgMDOIYSli8KWbv6fyLrgv/DhEo5r6QIzBDbjPTJAfIEAvcIX3EzBV7CBSK+3Ycx9YOJ5PZyssnArUiVRMb8zbVExa40RsC0OqAmeoTI3paQSDinBt0+7pU8x6AEICIMSbZRGe97eDd6LgMQIsjX+3iXFpeatHzXQBoMf1SsUQm5HJyi4YCoFU0jmL+qV3IvHoAIHK22m3duw+bGnM49QqBtIVb33vXj7bi3M3RcxuBQE7tmk2iaUOfpyf6BwGBOlkfjVjgHdlEhgh2mbtE7hdXP0IwzWxBkgAAAABJRU5ErkJggg=='
        }
      }
    },
    title: 'Pizza rolls fiasco',
    description: {
      raw: createRichText(
        'I fell asleep one night with pizza rolls in the oven. A fire alarm ensued and the SimpliSafe® system possibly saved my life. I awoke to the smoke alarm, and a phone call from SimpliSafe®. I can tell you how blessed we are to have you as our protectors when I cannot be.'
      )
    },
    author: 'Anonymous',
    listTitle: 'Smoke Alarm + Core Monitoring',
    list: [
      '24/7 protection that never sleeps',
      'Monitoring agents call for emergency dispatch of fire trucks and ambulances'
    ],
    note: ''
  },
  {
    color: '#00565C',
    backgroundImage: {
      description: 'Ping-pong sized hail',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=343&h=214&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=686&h=428&q=50&fm=webp 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=1372&h=856&q=50&fm=webp 1372w',
              sizes: '(min-width: 1372px) 1372px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=1372&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=343&h=214&q=50&fm=png 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=686&h=428&q=50&fm=png 686w,\nhttps://images.ctfassets.net/v6awd0kabi65/758hlfe5DXy9bqmrNZDoMR/149758fcc0282f82b4ee115c89985ebc/broken_window_1_1.png?w=1372&h=856&q=50&fm=png 1372w',
            sizes: '(min-width: 1372px) 1372px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1372,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAACiFBMVEXT08/S0s3JycTS0cq9vrtTVlgqKSkoJyclJSUiIiJcW1nFxb66v7zW1Mvq5t2zsKVpaWBobGiEhH/GxsDExL/Dwr6+vbfHx8Hq5t5oZGAjIyMgICAeHh5MS0m/vrbf3tjy7+na1s26tquuraBzdGt5d3C7u7XPz8rQz8rKycTNzcbt6uJoZ2QfHx8dHR0aGhoZGRmioJnT08zz8evV1MvJx7zAvrKZmI9ubWiqq6XS087S0c3R0MzIyMHY0cUtLS0YGBkWFhYUFBQTExQVFRV1dHDLy8Ps6ePv6+Ooq6VVXF42Oz6IiITAwLu/vrnDw7zb2M8yMjIREREQDw8UFBVvcW/JycLFxLzW08isrqZlbW1ES08wNDZdXl3S0s7T0858g4MlKCwREBAPDg4NCwwLCAgVFBNeX17ExL7Y1s3DwbU9Q0YxNTcyNzpOUVLT1M/U08/JyMJ1ensMCgsPDQwOCwoMCAcMCAYOCQdsZl6zsqzU08upq6FPV1o/RUk3PD5ER0VtbWbDxL/Bwr3BwbyFh4QlISATDgsPCgcNCAYNCQcQCghVTEWYmpeMkIpgZ2VdYV5wc2qEhXpUVlHP0MvT0s7Fxb+fn5lgXlgaFRIQCwgNCggPCwgWExGan528vbWio5qOk4uBhHxhZ2NOVFTT1NC1tKt3c2ZAPjoeGhgVExApKCVka2uXm5jAwLrR0Mi+v7egpaCnqaKSlZGNkpDR0s/Nzsm3uLF/gXuEh4OKi4aSlZCxrqWtsKq1t7K3t7G6urS0s6yysqy1ta62trC4ubS6vLi+v7rCw76+v7mwsaqys62ys6yztK21tK64uLK7urW8vLbCwbvMy8bQ0MvOz8rOz8vP0MycClifAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAAHNJREFUCNdjZGDEAkTA5HshZDEWYbjUfyY0QRi4aACmfBlBpn4UYEQynEXwrsqaUEZBGP+BIojM/yCIYTmLsAjjpHwY74ksRFAod0oDiHFPGSQI1bX0FMPkJUD6Cy+SdoH3EaIgebjrQa44hmrHAQZHRkYARcYWt5iuHe4AAAAASUVORK5CYII='
        }
      }
    },
    deviceImage: {
      description: 'Glassbreak Sensor',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/23SGmIzXs8oqoFEga5lm3f/7b6d4aded284223420096d5c1c5e95af/Glassbreak.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABAlBMVEUAAAC+vr69vb2+vr7CwsLJycnPz8/V1dXZ2dnd3d3g4ODi4uLk5OTm5ubo6Ojp6em9vr3p6em9vr7p6enp6eno6Oi9vr7o6Oi+vr69vr6+vr7o6Oi+vr7o6Oi+vr7n5+etra2jo6Ovr6+4uLjCwsLKysrR0dHV1dXZ2dnc3Nzd3d3c3NzZ2dnT09PLy8sAAAAAAAAAAAAAAAC9vb3BwcHIyMjPz8/U1NTY2Njc3Nzg4ODi4uLl5eXm5ubo6Ojk5OTf39/R0dHV1dXW1tba2trQ0NDT09Pn5+fExMTMzMzKysrHx8fb29vd3d2hoaHh4eHFxcXJycnOzs7X19fj4+Pe3t5GFrFhAAAAM3RSTlMAT8HR1dfZ2tra2dfV0cJT1tvm7Ovr5erl5OTp4+jL0T/M2+Ln7O7v7uvm39fBRggHBgSDw6o4AAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUKDSczDQWurgAAAJhJREFUGBnlwUEKglAUhtHv/j4Dc2Dgc1KToP2vpi00yiZhFIVp3kqjttA5/Af7cWQQig32scVJTBIQToxmTITLmc8DA8OJweJchFOXQcaTgNjYPSn7rgXDKUASq0u6D0QQLgjSllm9aLiKF3Wwq/trzKwKvCkBlpe8P0QxkgG3ojmUDfQEnMTTCqhAOAlXQMVAOFvbN8zsATcLHlJ9qBt+AAAAAElFTkSuQmCC'
        }
      }
    },
    title: 'Ping-pong sized hail',
    description: {
      raw: createRichText(
        'I was at a funeral when the ping-pong sized hail and rain hit. I got a text notification on my phone that my hallway glassbreak sensor went off. I called my husband (who is getting a call from the monitoring service at the same time) to come home ASAP.'
      )
    },
    author: 'Caprice A.',
    listTitle: 'Glassbreak + Core Monitoring',
    list: [
      'Glassbreak Sensor detects the distinct sound of breaking glass',
      'Monitoring agents alert you and, if necessary, call the police'
    ],
    note: ''
  },
  {
    color: '#C84A3B',
    backgroundImage: {
      description: 'Wine, On Time',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=340&h=214&q=50&fm=webp 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=680&h=428&q=50&fm=webp 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=1360&h=856&q=50&fm=webp 1360w',
              sizes: '(min-width: 1360px) 1360px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=1360&h=856&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=340&h=214&q=50&fm=png 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=680&h=428&q=50&fm=png 680w,\nhttps://images.ctfassets.net/v6awd0kabi65/6z7Bit26qLwUbDUA0lSv1h/b49786265adc230f30a960a194f0147c/image_164.png?w=1360&h=856&q=50&fm=png 1360w',
            sizes: '(min-width: 1360px) 1360px, 100vw'
          }
        },
        layout: 'constrained',
        width: 1360,
        height: 856,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUJEwAusMtRhgAAAsFJREFUGBkFwVtv21QAAOBz893Oxc2SJk3XFpJ2WotIEBKCISEmBGJPk/bf9sYr2h+YxAPS4AEhnlhXAVtXbU22LnFrx/dj+/j4HL4PPv7p54cPvuMlg0hRVAMhJCGUUhYJxQrSbVMzdSBkHoSbgj799beld7XxvDuzw6P9Yzz//Phi9dZSVCIkzShgPA+DMglZHuXxZr26fvJs4ZRhUcTni8UqCYtKcCg3WfzJ5C4pGes6I1Y3YRhoCpKNIWRdVhGvkaoaRVL4y/iyM7jdw+8vX8ar4Gb1wd3tOb1hnnwgTS1caxshZHeRbDitmG6Str2jqsYfp8vTvxfT6lJXv7hOZFlkIn7d1TpDtQWYwEwQgaiuR7ap/fPqXZ5yKUWv6zQcfP3V91lw/tfzxQssJ+z8/jc7akvXd0ZZwlNMLaXcJBUJE+/Vm+ftoeNn1Wadxt4Gy+p4Ovevr9ukzrwbKti3s65O7AYgLmAPyC0CKwQqoyGuubVO8w20klBEQarYtiY0TTNLWrlKbcJqvGPutmUVrePX/6Y0U+1OW2JHaf3y8hlxHIvW8vLsAiFcJ3lnAFvucG//QCL4+4vlj/eO0ordPZlf+Z6iQ+IndZgx7JiC+Fc+CVkSlaAWUDIkOC1uQGd7KiUEotm7xVuKfnj4maEqjmq/O7ugPNUMNwiuyrrYC/p49OkYrOu3f/6XeYGBrflsdnI4NVQdShAGy26/Z2LudLcdy7L7gzxKMz/gGNOc60jB2sju7fUn7fH8ZPbw0aM704llWAgTIURE65ZtqqoEgq/fnEFN2T+euaPbkZ8iCA2dEAI0yeT9Bz/sb+8KQBACWFEYaxBCjuMynpWsuXl/miXBrY+/5Gpra6zeG4yCxBMVJxhJmxuT8UFVcyA4hKSgFCEMIRj03E0sojLnXHaHR5rdz7MkztKqpo7Z2vro4H+on4zw7MMsUwAAAABJRU5ErkJggg=='
        }
      }
    },
    deviceImage: {
      description: 'Video Doorbell',
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=178&h=235&q=50&fm=webp 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=356&h=470&q=50&fm=webp 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=712&h=940&q=50&fm=webp 712w',
              sizes: '(min-width: 712px) 712px, 100vw',
              type: 'image/webp'
            }
          ],
          fallback: {
            src: 'https://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=712&h=940&q=50&fm=png',
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=178&h=235&q=50&fm=png 178w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=356&h=470&q=50&fm=png 356w,\nhttps://images.ctfassets.net/v6awd0kabi65/7t1r0nxpXmcFWEcYNplzEj/2c9f5a4c66a86244517003dbf1a4cf7d/Video_Doorbell.png?w=712&h=940&q=50&fm=png 712w',
            sizes: '(min-width: 712px) 712px, 100vw'
          }
        },
        layout: 'constrained',
        width: 712,
        height: 940,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAMAAACAXYxPAAABcVBMVEUAAACkpaXr7Ovv8O/d397n6Ofc3dzt7e3y8vLW2Nfa2trs7ez29vbd397y8vLf4eDv8O/f4eDv8O/f4N/v7+/v7+7u7+7e4N/u7u7t7u3e397d397t7e3P0tHt7ezAxcTs7ezCxcTt7u2/wsC7xMXk5+fs7ezAwsHMzs3N1tfa3+Dk5eTn5+bV1tVPT08VFRUZGhp0dHRzdHQXFxcSEhIWFhYZGRqsrKxLS0seHh4TExMYGBgbHByCg4J5eXkXGBgUFRUWFxeur67f4N9eXl4cHR0hIiKBgYHq6+ro6ejh4eHl5uXq6upfX1+LjIvp6ulnZ2eQkZDf39/q6uno6Ojp6enn6Ofp6ejJycjIyMbHx8XU1dPY4OG0w8e7yczg5eXH09WepKaSk5WUlZefqazb4uOisLSnqKmZmpyhoqS8vb6/zM+ktLi2t7inqKqrrK2sra6wv8OkvMCsra+Wl5ilpqi6zNGerrKXnaCWn6Kww8jF7bAGAAAALXRSTlMAABYPQ9f9uSAx+dwLpmLLiMyKzYqKis2Kis3Nis2KyIWSTxrmuwIcntrSfwin8CuMAAAACXBIWXMAACxLAAAsSwGlPZapAAAAB3RJTUUH5wUKDSczDQWurgAAAKNJREFUKM+l0T0KAkEMBeD3kkzWdRUURC8gbOHhBc9i5R1sdMUfZLGx2ETEwmGqjzdheAH+PRRNIjDbaDWKsZprkgd2A9S6VVVdHe8DNHGSpISZ4n4ppQQ0KVzmpMFB8pm+BKgq+ImwiAZIjyo9b3B15JmC9w0zG5w8FmKAzcgHE/ZnYBKTCl+Qt4gck6QrfrX0Hbtp7nNnNi9bCztCS5LcD/EFFfQTKjCfVEoAAAAASUVORK5CYII='
        }
      }
    },
    title: 'Wine, On Time',
    description: {
      raw: createRichText(
        "Honestly the number one reason I have a SimpliSafe® alarm system is so I can talk to the delivery person through the video doorbell and tell them to leave my wine while I'm at work."
      )
    },
    author: 'Alexia Loannou',
    listTitle: 'Doorbell + Core Monitoring',
    list: [
      'Built-in motion sensor sends a notification and captures video (with a monitoring plan) when anyone approaches',
      'During an alarm, monitoring agents can view the recordings to verify activity when requesting police dispatch*'
    ],
    note: '*Requires professional monitoring'
  }
]
