import type { IconTypes } from '@ecomm/shared-icons'

import { Locale } from '../../hooks/useLocalizedData'
import { createMockRichText } from '../../utils'

const breakInIllustration = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
          sizes: '(min-width: 1932px) 1932px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/lzPxx0ferIthUcXSTFsdI/720361a0a2c4773e28437d37b15a66c0/break_in_2x_-qly.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
        sizes: '(min-width: 1932px) 1932px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1932,
    height: 1056,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBAb/xAAjEAACAgEDBAMBAAAAAAAAAAABAgMEABESMSFBUaEFFBUi/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAB/8QAFREBAQAAAAAAAAAAAAAAAAAAABP/2gAMAwEAAhEDEQA/AN6WXhlDWomr1tSu+VNCW7aHxz6xFy87OopmKVdx3lZNSF89OO/OdavxtNV2/XQrxo39D3i/zqVdt8FSGJmOhKIATi0FNLjVmjUhWII6EjnDLdaNBAoCjQYZlFJ//9k='
    }
  },
  title: 'Illustration Break-in'
}

const fireIllustration = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
          sizes: '(min-width: 1932px) 1932px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/ZNDPXmOd2Rjr4cF7ZBohF/35a87df378ae532b48e55d4dddaac25c/fire_2x_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
        sizes: '(min-width: 1932px) 1932px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1932,
    height: 1056,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQGA//EACUQAAIBAwMDBQEAAAAAAAAAAAECAwAEEQUhMRIiQRMVMlGBof/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAAwL/2gAMAwEAAhEDEQA/AE7aOTubHZwd8c+f5TllNdR6pALdn6S4GAT0t95qvXQ9MVnYWcYL/LnetPbrOBS8UCqwGxHNO6iJAZIjRuvXF2NTf1SU2HSBxjHj9zRVm2n2lwFea3jkbGMsPFFXOgcg29Zqrf/Z'
    }
  },
  title: 'Illustration Fire'
}

const waterIllustration = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
          sizes: '(min-width: 1932px) 1932px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/6FPObnRIgxYPuGYJJRCSnC/96b2e183ad0ec7a22603abd1703a6141/water_2x_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
        sizes: '(min-width: 1932px) 1932px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1932,
    height: 1056,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAcCAwQG/8QAIhAAAQQBBAIDAAAAAAAAAAAAAQIDBBEABRIhMRQyQXGB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQP/xAAYEQADAQEAAAAAAAAAAAAAAAAAAhETIf/aAAwDAQACEQMRAD8AlGleQmmWyNiuFFvlR/e80eO0umJZLzrnsEiq+86pWlwlEksJs90SMrXpsOO0txlhKF13Zw6MWC/laPJEt4RmbZDigg7x63x84Yw4bDTsdK3EBSjfOGX2Yhip/9k='
    }
  },
  title: 'Illustration Water'
}

const tempIllustration = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
          sizes: '(min-width: 1932px) 1932px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/6wWdAKndtWXrMUkdUIi8A3/c8d7804569d556f612dc92d7a0c588d7/temp_hot_2x_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
        sizes: '(min-width: 1932px) 1932px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1932,
    height: 1056,
    placeholder: {
      fallback:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAMAAABI111xAAAB11BMVEX9/f37+/z////+/v76+vr5+fn8/Pz4+frp6erS0dDHz9Xj5OTv7+7/////9vD+38b95tP938bz387d2dXd2dbx7e/a6vTJzdGfnJqfnZydnqDGxcPc3NvRt7K7k4j8+/v+69vhtYXbvp6iz8f6vYrwyIbx6qDd253dv8Lm5eWtq6moqqumpaS1trXa2tnS09SjtsWtwM+rtb5vjpzgwp/Cxqz3tILWtHWquYGgxIH6/Pni4eGcmZeUkY+RlZi9vr6urqyDlaS+xMS1xM+2xdC9ydHhpIp2a2CBe2/lsXbntnPkvnn9/Pnl5OOGg4GNiomUlZaWqaqZjYehm5mruMO2wcm8xs7EzdLlqZmllpJEY2RWaEpqckU9Yl33+Pjd1MaXdkZ4dHJ2dnV3dHKBeXXRj39+sNN4q9Bvpcxdj7bcsKTCy87AwsO+r6u+kYXUn5H9+/r4+Pjj4uLpmIGBsNJqochknceeorP3wbL19fXs7ez+9/Tkj3hak714q89zqdClp7f6w7Pa29vMzMzh4eH96OL2mH2Up750qc5yqM6Yprr7z8P+/v7W1tbKy8vg4OD95d7GnJhql7pqmbt2k67++/rW19fLy8v+9fP839bf39/R0tLm5ubbP/tXAAAAB3RSTlP+/v7+/v7+sGAR7wAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+cFCg0zDwzEBXwAAABiSURBVAgdZcExDgFRGEbRe5MvMUZE8iqFNapMbRU6O6SgMXQmf/nmHDeKCiqWRw6uPDOfXAnNHqHZIzR7hCYLyw8GZ8Odxdlyg8nRUEYLXFVC2X1GF8DWr6EM7xccL0Dc+weMmQ0qHsi4UgAAAABJRU5ErkJggg=='
    }
  },
  title: 'Illustration Temp'
}

const packageTheftllustration = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
          sizes: '(min-width: 1932px) 1932px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/v6awd0kabi65/4xXJHecRJBtZjqehoEaVZm/605445cd185d842fca155d37c634e00e/package_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
        sizes: '(min-width: 1932px) 1932px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1932,
    height: 1056,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQG/8QAJBAAAgICAAQHAAAAAAAAAAAAAQIDEQAEBRQhMRMiQVJhcbH/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQACEQP/2gAMAwEAAhEDEQA/AN7TtLsMra4CrIEsmye1/uTxTYg5VYvElhkaqKEhj3zquVguxGAfjplOzwvS21An11cL2snpidqcgYB7JNESy6kbyiiVBDe8UOten1hjrV1IFjKhPKpoAkmgMMRuL53/2Q=='
    }
  },
  title: 'Illustration Package Theft'
}

export const data = {
  [Locale.EnGB]: {
    header: {
      title: 'During an alarm',
      description: `With the one-of-a-kind Fast Protect™ Monitoring Plan, our highly trained agents can act quickly in an emergency. Here's how.`
    },
    alarmCategories: [
      {
        id: 0,
        name: 'Break-in',
        image: breakInIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'When a burglary alarm is triggered, the siren sounds.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'Receive an Alarm Text instantly. You can cancel the alarm or request help right away.'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents check camera recordings and live video feeds for intruders, telling them police are on their way.*'
          },
          {
            icon: <IconTypes>'PoliceCar',
            text: 'Police are dispatched through 911 services, and verified threats are given priority.**'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents call you or your listed contacts directly to inform you of what has happened with your alarm.'
          }
        ],
        disclaimerText: createMockRichText([
          '*Requires Smart Alarm indoor camera and live guard protection.',
          '**Vs. non-verified SimpliSafe® alarms. With Video Verification, our agents capture evidence so that you can get priority response. Fast Protect™ Monitoring plan required.'
        ])
      },
      {
        id: 1,
        name: 'Fire',
        image: fireIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'The smoke detector is always armed. When it’s tripped, a loud siren sounds.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly so that you can take action.'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Monitoring agents call you directly so you can confirm or cancel the alarm.'
          },
          {
            icon: <IconTypes>'Help',
            text: 'If our agents can’t reach you, they will escalate the alarm to 911 dispatch.'
          },
          {
            icon: <IconTypes>'Alert',
            text: 'Unless you cancel the alarm, firefighters and medical personnel are dispatched through 911 center.'
          }
        ]
      },
      {
        id: 2,
        name: 'Water',
        image: waterIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'As soon as the sensor detects water, the base station provides a spoken alert.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly. If you have multiple sensors, it will specify which one.'
          },
          {
            icon: <IconTypes>'Phone',
            text: 'We call the names on your contact list to help ensure you’re notified.'
          },
          {
            icon: <IconTypes>'Clock',
            text: 'The sensor won’t respond again until you reset the system, giving you time to resolve the issue.'
          }
        ]
      },
      {
        id: 3,
        name: 'Temp',
        image: tempIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'When the sensor detects a high or low temp, the base station provides a spoken alert.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly. If you have multiple sensors, it will specify which one.'
          },
          {
            icon: <IconTypes>'Phone',
            text: 'We call the names on your contact list to help ensure you’re notified.'
          },
          {
            icon: <IconTypes>'Clock',
            text: 'The sensor won’t respond again until you reset the system, giving you time to resolve the issue.'
          }
        ]
      },
      {
        id: 4,
        name: 'Package Theft',
        image: packageTheftllustration,
        bubbles: [
          {
            icon: <IconTypes>'PersonRunning',
            text: 'The Video Doorbell Pro senses motion, starts recording & lights up to signal that it’s in use.*'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly. With one tap, open the live video view.'
          },
          {
            icon: <IconTypes>'Microphone',
            text: 'You can talk to whoever’s at the door with clear, 2-way audio.'
          },
          {
            icon: <IconTypes>'Video',
            text: 'Recordings can be used as evidence for police investigation.**'
          },
          {
            icon: <IconTypes>'Video',
            text: 'If you file a claim with your insurance, you’ll have video recordings as evidence.***'
          }
        ],
        disclaimerText: createMockRichText([
          '*Requires Video Doorbell Pro and Monitoring plan.',
          '** Agents do not send video. You will need to submit video directly to police.',
          '*** SimpliSafe® does not guarantee reimbursement by your insurance company in the event of stolen deliveries.'
        ])
      }
    ]
  },
  [Locale.EnUS]: {
    header: {
      title: 'During an alarm',
      description: `With the one-of-a-kind Fast Protect™ Monitoring Plan, our highly trained agents can act quickly in an emergency. Here's how.`
    },
    alarmCategories: [
      {
        id: 0,
        name: 'Break-in',
        image: breakInIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'When a burglary alarm is triggered, the siren sounds.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'Receive an Alarm Text instantly. You can cancel the alarm or request help right away.'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents check camera recordings and live video feeds for intruders, telling them police are on their way.*'
          },
          {
            icon: <IconTypes>'PoliceCar',
            text: 'Police are dispatched through 911 services, and verified threats are given priority.**'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents call you directly to inform you of what has happened with your alarm and that police have been dispatched.'
          }
        ],
        disclaimerText: createMockRichText([
          '*Requires Smart Alarm indoor camera and live guard protection.',
          '**Vs. non-verified SimpliSafe® alarms. With Video Verification, our agents capture evidence so that you can get priority response. Fast Protect™ Monitoring plan required.'
        ])
      },
      {
        id: 1,
        name: 'Fire',
        image: fireIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'The smoke detector is always armed. When it’s tripped, a loud siren sounds.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly so that you can take action.'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Monitoring agents call you directly so you can confirm or cancel the alarm.'
          },
          {
            icon: <IconTypes>'Help',
            text: 'If our agents can’t reach you, they will escalate the alarm to 911 dispatch.'
          },
          {
            icon: <IconTypes>'Alert',
            text: 'Unless you cancel the alarm, firefighters and medical personnel are dispatched through 911 center.'
          }
        ]
      },
      {
        id: 2,
        name: 'Water',
        image: waterIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'As soon as the sensor detects water, the base station provides a spoken alert.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly. If you have multiple sensors, it will specify which one.'
          },
          {
            icon: <IconTypes>'Phone',
            text: 'We call the names on your contact list to help ensure you’re notified.'
          },
          {
            icon: <IconTypes>'Clock',
            text: 'The sensor won’t respond again until you reset the system, giving you time to resolve the issue.'
          }
        ]
      },
      {
        id: 3,
        name: 'Temp',
        image: tempIllustration,
        bubbles: [
          {
            icon: <IconTypes>'Siren',
            text: 'When the sensor detects a high or low temp, the base station provides a spoken alert.'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly. If you have multiple sensors, it will specify which one.'
          },
          {
            icon: <IconTypes>'Phone',
            text: 'We call the names on your contact list to help ensure you’re notified.'
          },
          {
            icon: <IconTypes>'Clock',
            text: 'The sensor won’t respond again until you reset the system, giving you time to resolve the issue.'
          }
        ]
      },
      {
        id: 4,
        name: 'Package Theft',
        image: packageTheftllustration,
        bubbles: [
          {
            icon: <IconTypes>'PersonRunning',
            text: 'The Video Doorbell Pro senses motion, starts recording & lights up to signal that it’s in use.*'
          },
          {
            icon: <IconTypes>'PhoneRinging',
            text: 'The app notifies you instantly. With one tap, open the live video view.'
          },
          {
            icon: <IconTypes>'Microphone',
            text: 'You can talk to whoever’s at the door with clear, 2-way audio.'
          },
          {
            icon: <IconTypes>'Video',
            text: 'Recordings can be used as evidence for police investigation.**'
          },
          {
            icon: <IconTypes>'Video',
            text: 'If you file a claim with your insurance, you’ll have video recordings as evidence.***'
          }
        ],
        disclaimerText: createMockRichText([
          '*Requires Video Doorbell Pro and Monitoring plan.',
          '** Agents do not send video. You will need to submit video directly to police.',
          '*** SimpliSafe® does not guarantee reimbursement by your insurance company in the event of stolen deliveries.'
        ])
      }
    ]
  }
}
