import { useCallback, useState } from 'react'
import { CustomModal } from '../../components/CustomModal'
import { assets } from '../../experiments/ExitIntent/data'
import { ModalContext } from './ModalContext'
import type {
  ModalContextProps,
  ModalProviderProps,
  ModalTypes,
  SendLinkToCustomerModalProps,
  TwoButtonsProps
} from './types'

export function ModalProvider({ children }: ModalProviderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState<ModalTypes | null>(null)
  const [twoButtonsProps, setTwoButtonsProps] =
    useState<TwoButtonsProps | null>(null)
  const [sendLinkToCustomersProps, setSendLinkToCustomersProps] =
    useState<SendLinkToCustomerModalProps | null>(null)

  const openModal = useCallback((modalType: ModalTypes) => {
    setModalType(modalType)
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setModalType(null)
  }, [])

  const openTwoButtonsModal = useCallback((props: TwoButtonsProps) => {
    setTwoButtonsProps(props)
    setModalType('two-buttons')
    setIsOpen(true)
  }, [])

  const openSendLinkToCustomer = useCallback(
    (props: SendLinkToCustomerModalProps) => {
      setSendLinkToCustomersProps(props)
      setModalType('send-link-to-customer')
      setIsOpen(true)
    },
    []
  )

  const contextValue: ModalContextProps = {
    openModal,
    closeModal,
    isOpen,
    modalType,
    openTwoButtonsModal,
    openSendLinkToCustomer
  }

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <CustomModal
        assets={assets}
        isOpen={isOpen}
        modalType={modalType}
        onRequestClose={closeModal}
        sendLinkToCustomerProps={sendLinkToCustomersProps}
        twoButtonsProps={twoButtonsProps}
      />
    </ModalContext.Provider>
  )
}
